import { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import { Box, Typography, Button, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { tokens } from '../../theme';
import { useLanguage, langTokens } from '../../language';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import viLocale from 'date-fns/locale/vi';
import { useNavigate } from 'react-router-dom';
// import Cluster from '../../components/maps/Cluster';
import Heatmap from '../../components/maps/Heatmap.jsx';
import PlainMap from '../../components/maps/PlainMap';
import StatBox from '../../components/StatBox';
import SteppedFilter from '../../components/SteppedFilter.js';
import ResultBarChart from '../../components/ResultBarChart.jsx';
import SessionExpiredDialog from '../../components/SessionExpiredDialog.jsx';

import DirectionsBikeIcon from '@mui/icons-material/DirectionsBikeOutlined';
import SavingsLeafIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import SchoolIcon from '@mui/icons-material/SchoolOutlined';
// import RoadIcon from '@mui/icons-material/RoundaboutRightOutlined';
import RouteIcon from '@mui/icons-material/RouteOutlined';
import LocationOff from '@mui/icons-material/LocationOffOutlined';
import LocationOn from '@mui/icons-material/LocationOnOutlined';
import EyeOn from '@mui/icons-material/Visibility';
import EyeOff from '@mui/icons-material/VisibilityOff';

import 'leaflet/dist/leaflet.css';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import '../dashboard/Datetimerange.css';
import { nodes } from '../../data/geoCoding.js';

const Dashboard = (props) => {
  const navigate = useNavigate();
  const _timeRange = JSON.parse(localStorage.getItem('timeRange'));
  const [initData, setInitData] = useState(null);
  const [dataArray, setDataArray] = useState(null);
  const [quarterIndex, setQuarterIndex] = useState(null);
  const [dataLabel, setDataLabel] = useState('');
  const [value, setValue] = useState([new Date(parseFloat(_timeRange.from)), new Date(parseFloat(_timeRange.to))]);
  const [school, setSchool] = useState([]);
  const [roadData, setRoadData] = useState(null);
  // const [density, setDensity] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  // const [isCluster, setIsCluster] = useState(false);
  const [showEnds, setShowEnds] = useState(false);
  const [showSchools, setShowSchools] = useState(true);
  const [overlaysEnabled, setOverlaysEnabled] = useState(true);
  const isAdmin = JSON.parse(localStorage.getItem('user'))?.role === 'admin';
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { language } = useLanguage();
  const strLang = langTokens(language).dashboard;
  const quarters = ['05:00-08:00', '10:00-12:00', '12:00-14:00', '16:00-19:00'];

  const handleCloseAlertDialog = () => {
    setOpenAlertDialog(false);
    props.setLoggedIn(false);
    navigate(props.loggedIn ? '/login' : '/home');
  };
  const handleAPIerror = (err) => {
    if (err.response.status === 401) {
      localStorage.removeItem('user');
      setOpenAlertDialog(true);
    } else {
      console.error(err.response);
    }
  };
  const toggleEnds = () => {
    setShowEnds(!showEnds);
  };
  const toggleSchools = () => {
    setShowSchools(!showSchools);
  };
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const handleClickTimePeriod = (_date, _e) => {
    setDataArray(initData.filteredObj[_date][_e]);
    setDataLabel('ngày ' + format(new Date(_date), 'dd/LL/yy') + ' từ ' + _e);
  };
  const handleClickSchoolPeriod = (periodIndex) => {
    setQuarterIndex(periodIndex);
    setDataLabel('trường ' + school[1] + ' khung giờ ' + quarters[periodIndex - 1]);
  };
  const convertUTCToLocalTime = (utcString) => {
    const zonedDate = formatInTimeZone(new Date(utcString), 'Asia/Bangkok', 'yyyy-MM-dd HH:mm:ss');
    return zonedDate;
  };
  const WaitingIndicator = () => {
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        position={'absolute'}
        zIndex={1000}
        bgcolor='#cccccccc'
        width={'100%'}
        height={'100%'}
        top={0}
        left={0}>
        {isLoading && <CircularProgress />}
      </Box>
    );
  };
  // get schoolData
  useEffect(() => {
    // console.log(JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).token : null);
    const apiURL = process.env.REACT_APP_SCHOOL_LOCATIONS;
    const fetchData = async () => {
      try {
        const response = await axios.get(apiURL, {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
          },
        });
        if (response.status === 200) localStorage.setItem('schoolData', JSON.stringify(response.data));
      } catch (error) {
        handleAPIerror(error);
      }
    };
    if (localStorage.getItem('schoolData') === null) fetchData(); //only fetch if School data do not exist
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1000);

    // Clean up the timeout if the component is unmounted
    return () => clearTimeout(timeoutId);
  }, [isExpanded]);
  // Get data
  useEffect(() => {
    const _initData = localStorage.getItem('initData');

    const getNodesBySchoolId = (schoolId) => {
      const foundNode = nodes.find((node) => node.schoolID === schoolId);
      return foundNode ? foundNode.ids : [];
    };
    const apiURL = school.length > 0 ? process.env.REACT_APP_API_FULLDATAWITHID : process.env.REACT_APP_API_FULLDATAPRESETTC;
    const _params = {
      stt: 1,
      from: new Date(value[0]).getTime() + 'ms',
      to: new Date(value[1]).getTime() + 'ms',
      ...(school.length > 0 && { ID: getNodesBySchoolId(school[0]) }),
    };

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(apiURL, {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
          },
          params: {
            json: JSON.stringify(_params),
          },
        });

        if (response.status === 200 && response.data.count > 0) {
          // console.log(response.data);
          const filtererDt = filterGPSData_v3(response.data);
          const firstDay = Object.keys(filtererDt.filteredObj).sort()[0];
          const firstQuarter = Object.keys(filtererDt.filteredObj[firstDay]).sort()[0];

          setDataArray(filtererDt.filteredObj[firstDay][firstQuarter]);
          setDataLabel(
            school.length > 0
              ? 'trường ' + school[1] + ' khung giờ 05:00-08:00'
              : 'ngày ' + format(new Date(firstDay), 'dd/LL/yy') + ' từ ' + firstQuarter
          );
          setInitData(filtererDt);
          localStorage.setItem('initData', JSON.stringify(filtererDt));
        } else {
          alert(language === 'en' ? 'No data found for the selected time period' : 'Không có dữ liệu trong khoảng thời gian đã chọn');
          setDataArray(null);
          setDataLabel(null);
          setInitData(null);
          localStorage.removeItem('initData');
        }
      } catch (error) {
        console.log(error);
        handleAPIerror(error);
      } finally {
        const timeRange = {
          from: _params.from,
          to: _params.to,
        };
        localStorage.setItem('timeRange', JSON.stringify(timeRange));
        if (school.length > 0) {
          localStorage.setItem(
            'currentSchool',
            JSON.stringify({
              id: school[0],
              schoolName: school[1],
              Lat: school[2],
              Lon: school[3],
              address: school[4],
              start: school[8],
              end: school[9],
            })
          );
          setQuarterIndex(1);
        } else {
          localStorage.removeItem('currentSchool');
          setQuarterIndex(null);
        }
        setIsExpanded(false);
        setIsLoading(false);
      }
    };

    if (_initData === null) {
      console.log('Initdata is null');
      fetchData();
    } else {
      const _storedTimeRange = JSON.parse(localStorage.getItem('timeRange'));
      if (_storedTimeRange?.from !== _params.from || _storedTimeRange?.to !== _params.to) {
        console.log('Time range different');
        fetchData();
      } else {
        console.log('Same time range as stored');
        const storedSchool = localStorage.getItem('currentSchool') ? JSON.parse(localStorage.getItem('currentSchool')) : null;
        if (storedSchool) {
          if (school.length > 0 && storedSchool.id !== school[0]) fetchData();
          else {
            //no new school or same school
            setSchool([
              storedSchool.id,
              storedSchool.schoolName,
              storedSchool.Lat,
              storedSchool.Lon,
              storedSchool.address,
              '',
              '',
              '',
              storedSchool.start,
              storedSchool.end,
            ]);
            const parsedData = JSON.parse(_initData);
            const firstDay = Object.keys(parsedData.filteredObj).sort()[0];
            const firstQuarter = Object.keys(parsedData.filteredObj[firstDay]).sort()[0];
            setDataArray(parsedData.filteredObj[firstDay][firstQuarter]);
            setDataLabel('trường ' + storedSchool.schoolName + ' khung giờ 05:00-08:00');
            setInitData(parsedData);
          }
        } else {
          const parsedData = JSON.parse(_initData);
          const firstDay = Object.keys(parsedData.filteredObj).sort()[0];
          const firstQuarter = Object.keys(parsedData.filteredObj[firstDay]).sort()[0];
          setDataArray(parsedData.filteredObj[firstDay][firstQuarter]);
          setDataLabel('ngày ' + format(new Date(firstDay), 'dd/LL/yy') + ' từ ' + firstQuarter);
          setInitData(parsedData);
        }
      }
    }
    // const hasSchoolData = storedSchool && school.length > 0;
    // // if (!storedSchool || storedSchool.id !== school[0]) {
    // if (hasSchoolData && storedSchool !== school[0]) {
    //   console.log('A school is stored and new school is selected');
    //   fetchData();
    // } else {
    //   console.log('No school stored or same school selected');
    // const parsedData = JSON.parse(_initData);
    // const firstDay = Object.keys(parsedData.filteredObj).sort()[0];
    // const firstQuarter = Object.keys(parsedData.filteredObj[firstDay]).sort()[0];
    // setDataArray(parsedData.filteredObj[firstDay][firstQuarter]);
    // setDataLabel(
    //   school.length > 0
    //     ? 'trường ' + school[1] + ' khung giờ 05:00-08:00'
    //     : 'ngày ' + format(new Date(firstDay), 'dd/LL/yy') + ' từ ' + firstQuarter
    // );
    // setInitData(parsedData);
    // }
    // eslint-disable-next-line
  }, [value]);
  // Get roadData related to the selected school
  useEffect(() => {
    const getRoads = async (_id) => {
      const apiURL = process.env.REACT_APP_API_GET_ROADS;
      const _params = {
        schoolID: _id,
      };
      try {
        const response = await axios.get(apiURL, {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
          },
          params: {
            json: JSON.stringify(_params),
          },
        });

        if (response.status === 200) {
          const dt = response.data.road;
          const processedData = dt.map((item) => {
            const _plot =
              item[7] === null
                ? [
                    [item[2], item[4]],
                    [item[3], item[5]],
                  ]
                : JSON.parse(item[7]);
            return {
              id: item[0],
              road: item[1],
              lat1: item[2],
              lat2: item[3],
              long1: item[4],
              long2: item[5],
              schoolID: item[6],
              plot: _plot,
              count: item[8],
              ratio: item[9],
              color: item[10],
            };
          });
          // console.log(processedData);
          setRoadData(processedData);
        }
      } catch (error) {
        handleAPIerror(error);
      }
    };
    if (school.length > 0) {
      getRoads(school[0]);
    }
  }, [school]);

  const groupByDayQuarterInLocalTime = (data) => {
    const result = {};
    data.forEach((item) => {
      // Convert the GMT time to the local time zone from GMT time string and Format the local date-time
      const localTimeString = convertUTCToLocalTime(item.time);
      // Extract the local date and hour
      const [localDate, localHour] = localTimeString.split(' ');
      const localHourInt = parseInt(localHour);

      // Determine the quarter based on the hour
      let quarter;
      if (localHourInt >= 5 && localHourInt < 8) {
        quarter = '05:00-08:00';
      } else if (localHourInt >= 10 && localHourInt < 12) {
        quarter = '10:00-12:00';
      } else if (localHourInt >= 12 && localHourInt < 14) {
        quarter = '12:00-14:00';
      } else {
        quarter = '16:00-19:00';
      }

      if (!result[localDate]) {
        result[localDate] = {};
      }

      if (!result[localDate][quarter]) {
        result[localDate][quarter] = [];
      }

      result[localDate][quarter].push(item);
    });
    // console.log(result);
    return result;
  };
  const filterGPSData_v3 = (dataArr) => {
    // const standStillThreshold = 0; //bottomThresholdSpeed * period = 40 metre !!! Tam doi sang 20m
    const filteredObj = {};
    let _totalDistance = 0;

    Object.keys(dataArr).forEach((device_id) => {
      if (device_id !== 'count') {
        const _dt = dataArr[device_id];
        const _dt_Days = groupByDayQuarterInLocalTime(_dt); //grouped data into days and then into quarters.
        // console.log('_dt_Days', _dt_Days);
        Object.keys(_dt_Days).forEach((day) => {
          if (!filteredObj[day]) filteredObj[day] = {};
          //each day
          let _dt_Days_per_day = _dt_Days[day];
          Object.keys(_dt_Days_per_day).forEach((quarter) => {
            if (!filteredObj[day][quarter]) filteredObj[day][quarter] = {};
            const dt2 = _dt_Days_per_day[quarter].map((e) => ({ Lat: e.Lat, Long: e.Long, time: e.time }));
            if (dt2.length < 3) return;
            else {
              let _tempTotalDistance = 0,
                _prevPt = dt2[0];
              const dt2Len = dt2.length;
              for (let i = 1; i < dt2Len; i++) {
                const _currPt = dt2[i];
                const _dist = calcD(_currPt, _prevPt);
                _tempTotalDistance += _dist;
                _prevPt = _currPt;
              }

              filteredObj[day][quarter][device_id] = dt2;
              _totalDistance += _tempTotalDistance;
            }
          });
        });
      }
    });

    const result = { filteredObj, totalDistance: Math.round(_totalDistance / 10) / 100 };
    // console.log(result);
    return result;
  };
  const toCartesian = (lat, long) => {
    const R = 6371; // Earth's radius in kilometers
    const latRad = (lat * Math.PI) / 180;
    const lonRad = (long * Math.PI) / 180;

    const x = R * Math.cos(latRad) * Math.cos(lonRad);
    const y = R * Math.cos(latRad) * Math.sin(lonRad);
    const z = R * Math.sin(latRad);

    return { x, y, z };
  };
  const calcAngle = (coord1, coord2, coord3) => {
    const p1 = toCartesian(coord1.Lat, coord1.Long);
    const p2 = toCartesian(coord2.Lat, coord2.Long);
    const p3 = toCartesian(coord3.Lat, coord3.Long);

    const v1 = {
      x: p1.x - p2.x,
      y: p1.y - p2.y,
      z: p1.z - p2.z,
    };

    const v2 = {
      x: p3.x - p2.x,
      y: p3.y - p2.y,
      z: p3.z - p2.z,
    };

    const dotProduct = v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;
    const magnitudeV1 = Math.sqrt(v1.x * v1.x + v1.y * v1.y + v1.z * v1.z);
    const magnitudeV2 = Math.sqrt(v2.x * v2.x + v2.y * v2.y + v2.z * v2.z);

    const cosineTheta = dotProduct / (magnitudeV1 * magnitudeV2);
    const angle = Math.acos(cosineTheta) * (180 / Math.PI); // Convert radians to degrees
    if (angle < 35 && calcD(coord2, coord2) < 40) {
      return false;
    } else {
      return true;
    }
  };
  const calcD = (coord1, coord2) => {
    const R = 6371 * 1000; // Radius of the Earth in meters
    const toRad = (degrees) => {
      return degrees * (Math.PI / 180);
    };
    const lat1 = toRad(coord1.Lat);
    const lon1 = toRad(coord1.Long);
    const lat2 = toRad(coord2.Lat);
    const lon2 = toRad(coord2.Long);

    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    const a = Math.sin(dLat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;

    return distance; // Distance in meters
  };
  const calculateSavingCO2 = (distance, kg) => {
    const _convertCoEff = kg ? 1e3 : 1e6;
    const _co2 = (distance * 45.68) / _convertCoEff;
    const roundingCoeff = _co2 < 1 ? 1e4 : 1e2;
    return Math.round(_co2 * roundingCoeff) / roundingCoeff;
  };
  const statBoxCss = {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    borderRadius: '10px',
    gridColumn: 'span 1',
  };
  const paddingNumber = (num) => {
    const numStr = num.toString();
    return numStr.padStart(3, '0');
  };
  const ChartSummary = () => {
    const dt = Object.values(initData)[0];

    return (
      <>
        {Object.keys(dt)
          .sort()
          .map((date) => {
            const _dt = dt[date];
            return (
              <Box
                key={date}
                mt={'0.5em'}
                sx={{
                  '& button': {
                    p: '0 1.5em',
                    minWidth: '0',
                    marginLeft: '1em',
                    fontSize: '0.7em',
                    letterSpacing: '0.2px',
                    color: colors.primary[600],
                    bgcolor: colors.greenAccent[500],
                  },
                  '& button:hover': {
                    bgcolor: colors.greenAccent[100],
                  },
                }}>
                <b>+ Ngày: {format(new Date(date), 'EEE dd/LL/yy', strLang.LOCALE === 'vi-VI' ? { locale: viLocale } : '')}</b>
                <br />
                {Object.keys(_dt)
                  .sort()
                  .map((quarter, idx) => {
                    return (
                      <p style={{ marginLeft: '0.75em', fontSize: '0.9em' }} key={idx}>
                        - {quarter}: <b>{paddingNumber(Object.keys(_dt[quarter]).length)}</b> {language === 'en' ? 'trips' : 'chuyến'}
                        <Button
                          onClick={() => (
                            setDataArray(initData.filteredObj[date][quarter]),
                            setDataLabel('ngày ' + format(new Date(date), 'dd/LL/yy') + ' từ ' + quarter)
                          )}>
                          <b>Xem</b>
                        </Button>
                        <br />
                      </p>
                    );
                  })}
              </Box>
            );
          })}
      </>
    );
  };
  // console.log(props.loggedIn);
  return (
    <Box m='-53px 0 0 15px' width={'calc(100% - 25px)'}>
      <Box display='flex' justifyContent='flex-start' alignItems='center'>
        <Header title={strLang.DASHBOARD} subtitle={strLang.WELCOME} />
      </Box>
      {/* GRID & CHARTS */}
      <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gridAutoRows='calc(100vh / 6 - 27px)' gap={'10px'}>
        {/* ROW 1 */}
        <Box sx={statBoxCss} bgcolor={colors.primary[400]}>
          <StatBox
            title={strLang.TOTAL_DISTANCE}
            subtitle={strLang.TRAVEL}
            figure={initData !== null ? Math.round(initData.totalDistance * 100) / 100 : 0}
            unit={'km'}
            icon={<DirectionsBikeIcon sx={{ color: colors.greenAccent[300], fontSize: '32px' }} />}
            tutorial={{ title: strLang.TOTAL_DISTANCE, desc: strLang.TOTAL_DISTANCE_DESC }}
          />
        </Box>
        <Box bgcolor={colors.greenAccent[950]} sx={statBoxCss}>
          <StatBox
            title={strLang.CO2_EMISSION}
            subtitle={strLang.CO2_EMISSION_2}
            figure={initData !== null ? calculateSavingCO2(initData.totalDistance) : 0} //CO2 emmision for motorcycle: 45.68 g/km
            unit={strLang.CO2_UNIT}
            icon={<SavingsLeafIcon sx={{ color: colors.greenAccent[300], fontSize: '32px' }} />}
            tutorial={{ title: strLang.CO2_EMISSION, desc: strLang.CO2_EMISSION_DESC }}
          />
        </Box>
        <Box sx={statBoxCss} bgcolor={colors.primary[400]}>
          <SteppedFilter value={value} setValue={setValue} language={strLang} setSchool={setSchool} loggedIn={props.loggedIn} />
          {/*setDensity={setDensity} */}
        </Box>

        {/* Map */}
        <Box gridColumn={isExpanded ? 'span 3' : 'span 2'} gridRow='span 5' bgcolor={colors.primary[400]} sx={{ position: 'relative' }}>
          <Box position={'absolute'} top={'13px'} left={'calc(50% - 250px)'} width={'500px'} zIndex={401} textAlign={'center'}>
            <Typography
              variant='bold10'
              color={'#333'}
              border={'solid 1px ' + colors.greenAccent[200]}
              p={'0.3em 1.5em'}
              borderRadius={'1em'}
              bgcolor={colors.greenAccent[100]}>
              {dataLabel === null ? 'Không có dữ liệu trong khoảng thời gian đã chọn' : 'Đang xem dữ liệu ' + dataLabel}
            </Typography>
          </Box>
          <Box
            position='absolute'
            top='80px'
            left='10px'
            display='flex'
            flexDirection='column'
            zIndex={401}
            sx={{
              '& button': {
                minWidth: '34px',
                width: '34px',
                border: '1px solid rgba(20, 27, 45, 0.5)',
                bgcolor: '#ffffffcc',
                mb: '0.5em',
              },
            }}>
            <Tooltip title={isExpanded ? strLang.NORMAL_MAP : strLang.FULL_MAP} placement='right'>
              <Button variant='outlined' onClick={toggleExpand}>
                {isExpanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </Button>
            </Tooltip>
            <Tooltip title={showSchools ? strLang.HIDE_SCHOOL : strLang.SHOW_SCHOOL} placement='right'>
              <Button variant='outlined' onClick={toggleSchools}>
                <SchoolIcon />
              </Button>
            </Tooltip>
            <Tooltip title={overlaysEnabled ? strLang.HIDE_ROUTE : strLang.SHOW_ROUTE} placement='right'>
              <Button onClick={() => setOverlaysEnabled(!overlaysEnabled)}>{overlaysEnabled ? <EyeOff /> : <EyeOn />}</Button>
            </Tooltip>
            <Tooltip title={showEnds ? strLang.HIDE_ENDS : strLang.SHOW_ENDS} placement='right'>
              <Button variant='outlined' onClick={toggleEnds}>
                {showEnds ? <LocationOff /> : <LocationOn />}
              </Button>
            </Tooltip>
            <Box
              p='0.5em 0.25em'
              sx={{
                minWidth: '85px',
                width: '85px',
                bgcolor: '#ddddddcc',
                border: '1px solid #cccccccc',
                borderRadius: '5px',
              }}>
              <Typography variant='bold11' mb={'0.25em'}>
                {strLang.LEGEND}
              </Typography>
              <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} gap={'7px'}>
                <RouteIcon sx={{ width: '15px', color: 'blue' }} />
                <Typography variant='font9'>{strLang.LEGEND_ROUTE}</Typography>
              </Box>
              {showEnds && (
                <>
                  <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} mt='0.5em' gap={'7px'}>
                    <img src='/assets/start2.png' alt='Điểm bắt đầu lộ trình' width={'12px'} style={{ marginLeft: '2px' }} />
                    <Typography variant='font9'> {strLang.LEGEND_START}</Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} mt='0.5em' gap={'7px'}>
                    <img src='/assets/finish2.png' alt=' Điểm kết thúc lộ trình' width={'12px'} style={{ marginLeft: '2px' }} />
                    <Typography variant='font9'> {strLang.LEGEND_END}</Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} mt='0.5em' gap={'5px'}>
                    <img src='/assets/school.png' alt=' vị trí trường học' width={'14px'} style={{ marginLeft: '2px' }} />
                    <Typography variant='font9'> {strLang.LEGEND_SCHOOL}</Typography>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          {/* <Box width='100%' height={isExpanded ? 'calc(100vh / 6 * 5 - 95px)' : 'calc(100vh / 6 * 4 - 78px)'} id='map'> */}
          <Box width='100%' height={'100%'} id='map'>
            {isLoading && <WaitingIndicator isLoading={isLoading} />}
            {/* {school.length > 0 ? (
              <PlainMap schoolId={school[0]} roadData={roadData} dataArray={dataArray} viewMode={props.loggedIn ? 'private' : 'public'} />
            ) : ( */}
            {localStorage.getItem('schoolData') && (
              <Heatmap
                dataArray={dataArray}
                quarterIndex={quarterIndex}
                showEnds={showEnds}
                showSchools={showSchools}
                overlaysEnabled={overlaysEnabled}
              />
              //plot the first quarter of the first day
            )}
          </Box>
          {initData && (
            <Box
              position={'absolute'}
              bottom={'10px'}
              left={'10px'}
              zIndex={'401'}
              display={'flex'}
              flexDirection={'row'}
              sx={{
                '& button': {
                  mb: '0.2em',
                  color: '#333',
                  height: '24px',
                  fontSize: '0.8em',
                  fontWeight: 'bold',
                  bgcolor: '#ffffffcc',
                  border: 'solid 1px ' + colors.greenAccent[100],
                },
                '& button:hover': {
                  bgcolor: colors.greenAccent[100],
                  color: '#000',
                  border: 'solid 1px ' + colors.greenAccent[200],
                },
                '& button:nth-of-type(5n+1)': { bgcolor: colors.greenAccent[200], fontSize: '0.75em', color: 'black' },
              }}>
              {school.length > 0 && (
                <Box display={'flex'} flexDirection={'column'}>
                  <Button disabled>{school[1]}</Button>
                  <Button onClick={() => handleClickSchoolPeriod(1)}>05:00-08:00</Button>
                  <Button onClick={() => handleClickSchoolPeriod(2)}>10:00-12:00</Button>
                  <Button onClick={() => handleClickSchoolPeriod(3)}>12:00-14:00</Button>
                  <Button onClick={() => handleClickSchoolPeriod(4)}>16:00-19:00</Button>
                </Box>
              )}
              {school.length === 0 &&
                Object.keys(initData.filteredObj)
                  .sort()
                  .map((date, idx0) => {
                    return (
                      <Box mr={'0.3em'} key={idx0} display={'flex'} flexDirection={'column'}>
                        <Button disabled>
                          {format(new Date(date), 'eee, dd/LL/yy', strLang.LOCALE === 'vi-VI' ? { locale: viLocale } : '')}
                        </Button>

                        {Object.keys(initData.filteredObj[date])
                          .sort()
                          .map((el, idx1) => {
                            return (
                              <Button key={idx0 + '.' + idx1} onClick={() => handleClickTimePeriod(date, el)}>
                                {el}
                              </Button>
                            );
                          })}
                      </Box>
                    );
                  })}
            </Box>
          )}
        </Box>
        {!isExpanded && (
          <>
            <Box gridColumn='span 1' gridRow='span 5' bgcolor={colors.primary[400]} width={'100%'}>
              <Box m='15px 0' height={'calc(100% - 30px)'} overflow={'auto'}>
                <Box p='0 25px' width={'100%'}>
                  <Box sx={{ '& p': { margin: '6px 0' } }}>
                    <Typography variant='h5' fontSize={'17px'} fontWeight={'bold'} color={colors.greenAccent[300]} m={'0.5em 0'}>
                      I. {school.length > 0 ? strLang.SCHOOL_INFO : strLang.TIME_INFO}
                    </Typography>
                    {school.length > 0 ? (
                      <p>
                        - <b>{strLang.SCHOOL_NAME}: </b>
                        {school[1]}
                        <br />- <b>{strLang.SCHOOL_ADDRESS}: </b>
                        {school[4]}
                        <br />- <b>{strLang.SCHOOL_TIME}: </b>
                        {strLang.TIME_START} {school[8]} {strLang.TIME_END} {school[9]}
                      </p>
                    ) : (
                      <p>
                        - <b>{strLang.TIME_START}: </b>
                        {format(new Date(value[0]), 'EEE dd/LL/yy HH:mm', strLang.LOCALE === 'vi-VI' ? { locale: viLocale } : '')} {' - '}
                        <b>{strLang.TIME_END}: </b>
                        {format(new Date(value[1]), 'EEE dd/LL/yy HH:mm', strLang.LOCALE === 'vi-VI' ? { locale: viLocale } : '')}
                      </p>
                    )}

                    <Typography variant='h5' fontSize={'17px'} fontWeight={'bold'} color={colors.greenAccent[300]} m={'1.5em 0 0.5em'}>
                      II. {strLang.RESULT_CHARTS}
                    </Typography>
                    {school.length > 0 && roadData ? (
                      <ResultBarChart
                        resultData={roadData.sort((a, b) => b.ratio - a.ratio)}
                        labels={{
                          no: strLang.RESULT_NO,
                          routes: strLang.RESULT_ROUTES,
                          ratios: strLang.RESULT_RATIOS,
                          results: strLang.RESULT_RESULTS,
                        }}
                      />
                    ) : (
                      <>
                        <Box fontSize={'1em'}>{initData && <ChartSummary />}</Box>
                        {isAdmin && (
                          <Box fontSize={'1em'} mt='0.7em'>
                            <b>+ {language === 'en' ? "List of devices' ID: " : 'Danh sách ID thiết bị: '}</b>
                            {initData && (
                              <Box m={'0.3em 0 0 0.5em'}>
                                {Object.keys(initData.filteredObj).map((e) => {
                                  const uniqueIds = new Set(
                                    Object.values(Object.values(initData.filteredObj[e])).flatMap((obj) => Object.keys(obj))
                                  );
                                  return (
                                    <Box key={e} m='0.3em 0 0 0.3em'>
                                      <Typography fontSize={'0.9em'} fontWeight={'bold'}>
                                        - Ngày {format(new Date(e), 'dd/LL/yy')} ({Array.from(uniqueIds).length} thiết bị):{' '}
                                      </Typography>
                                      <Typography fontSize={'0.75em'}>{Array.from(uniqueIds).join(', ')}</Typography>
                                    </Box>
                                  );
                                })}
                              </Box>
                            )}
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <SessionExpiredDialog open={openAlertDialog} onClose={handleCloseAlertDialog} loggedIn={props.loggedIn} language={language} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;

/* Box at the bottom */
/* TẠM THỜI DISABLE - CÓ THỂ SỬ DỤNG TRONG TƯƠNG LAI */
/* <Box gridColumn='span 2' gridRow='span 1' bgcolor={colors.primary[400]}>
      <Box m='15px 0' height={'calc(100% - 30px)'} overflow={'auto'}>
        <Box p='0 20px' display='flex' justifyContent='space-between' alignItems='center'>
          <Box>
            <Typography variant='h4' fontWeight='bold' color={colors.grey[100]}>
              {strLang.ADDITIONAL_INFO}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box> */
/* <Button
            variant='outlined'
            onClick={toggleMap}
            fontSize='14px'
            startIcon={isCluster ? <RoadIcon /> : <RouteIcon />}
            sx={{
              position: 'absolute',
              bottom: '10px',
              left: 'calc(50% - 50px)',
              zIndex: 1000,
              minWidth: '100px',
              bgcolor: '#ffffffcc',
            }}>
            {isCluster ? strLang.ROUTES_MAP : strLang.CLUSTER_MAP}
          </Button> */

// const groupByDayInLocalTime = (data) => {
//   // console.log(data);
//   const result = {};
//   data.forEach((item) => {
//     // Convert the GMT time to the local time zone from GMT time string and Format the local date-time
//     const localTimeString = convertUTCToLocalTime(item.time);
//     // Extract the local date in YYYY-MM-DD format
//     const localDate = localTimeString.split(' ')[0];

//     if (!result[localDate]) {
//       result[localDate] = [];
//     }
//     result[localDate].push(item);
//   });
//   // console.log(result);
//   return result;
// };
