// SteppedFilter.js
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  useTheme,
  Typography,
  Popover,
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import { tokens } from '../theme';
import { useLanguage, langTokens } from '../language';
import { addMinutes, differenceInMinutes, format } from 'date-fns';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import DateRangeIcon from '@mui/icons-material/DateRangeOutlined';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import TimerIcon from '@mui/icons-material/AccessTimeOutlined';
import LocationIcon from '@mui/icons-material/AddLocationAltOutlined';

const SteppedFilter = (props) => {
  const _timeRange = JSON.parse(localStorage.getItem('timeRange'));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { language } = useLanguage();
  const strLang = langTokens(language).public;
  const densityOptions = ['80%', '60%', '40%', '20%', 'All'];

  const [open, setOpen] = useState(false);
  const [filterType, setFilterType] = useState('time');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedSubArea, setSelectedSubArea] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedDensity, setSelectedDensity] = useState([]);
  // const [selectedTypeText, setSelectedTypeText] = useState(strLang.TIME_FILTER_LABEL);
  // const [selectedText, setSelectedText] = useState(
  //   strLang.FROM + format(new Date(props.value[0]), 'd/LL/y HH:mm') + strLang.TO + format(new Date(props.value[1]), 'd/LL/y HH:mm')
  // );
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [schoolData, setSchoolData] = useState([]);

  useEffect(() => {
    const apiURL = process.env.REACT_APP_SCHOOL_LOCATIONS;
    const fetchData = async () => {
      try {
        const response = await axios.get(apiURL, {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
          },
        });

        setSchoolData(response.data);
        localStorage.setItem('schoolData', JSON.stringify(response.data));
        setDateRange(props.value);
      } catch (err) {
        console.error('Fetch dataPreSet: ' + err);
      }
    };
    if (localStorage.getItem('schoolData') === null) fetchData(); //only fetch if School data do not exist
    else setSchoolData(JSON.parse(localStorage.getItem('schoolData'))); //else get stored data
    // eslint-disable-next-line
  }, []);

  const handleOpen = (type) => {
    setFilterType(type);
    setOpen(true);
    resetSelections();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSwitchFilter = (type) => {
    setFilterType(type);
    resetSelections();
  };

  const resetSelections = () => {
    setSelectedCity('Gia Lai');
    setSelectedArea('Huyện Phú Thiện');
    setSelectedSubArea([]);
    setSelectedSchool('');
    setSelectedDensity(['All']);
    setDateRange([new Date(parseFloat(_timeRange.from)), new Date(parseFloat(_timeRange.to))]);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setSelectedArea('');
    setSelectedSchool('');
  };

  const handleAreaChange = (event) => {
    setSelectedArea(event.target.value);
    setSelectedSubArea([]);
  };

  const handleSubAreaChange = (event) => {
    console.log(event.target.value);
    const {
      target: { value },
    } = event;
    const subAreas = typeof value === 'string' ? value.split(',') : value;
    console.log(typeof subAreas);
    // if (subAreas.length === 1) {
    //   console.log('subAreas', subAreas, subAreas.length);
    //   const _selectedSubAreaData = selectedAreaData?.subareas.find((subarea) => subarea.name === subAreas[0]);
    //   const _selectedSchool = _selectedSubAreaData?.schools.length === 1 ? _selectedSubAreaData?.schools[0][1] : '';
    //   setSelectedSchool(_selectedSchool);
    // }
    setSelectedSubArea(subAreas);
  };

  const handleSchoolChange = (event) => {
    setSelectedSchool(event.target.value);
  };

  const handleDensityChange = (event) => {
    const value = event.target.value;
    setSelectedDensity((prev) => (prev.includes(value) ? prev.filter((d) => d !== value) : [...prev, value]));
    console.log(selectedDensity);
  };

  const handleConfirm = () => {
    // setSelectedTypeText(filterType === 'time' ? strLang.TIME_FILTER_LABEL : strLang.LOCATION_FILTER_LABEL);

    if (filterType === 'time') {
      const result = differenceInMinutes(new Date(dateRange[1]), new Date(dateRange[0]));
      if (!props.loggedIn && result > 2880) {
        dateRange[1] = addMinutes(new Date(dateRange[0]), 2880);
        alert(
          'LƯU Ý\n\n' +
            'Khoảng thời gian đã chọn dài hơn 2 ngày (48 giờ đồng hồ) và đã được điều chỉnh thành:\nTừ ' +
            format(new Date(dateRange[0]), 'd/LL/y HH:mm') +
            ' đến ' +
            format(new Date(dateRange[1]), 'd/LL/y HH:mm')
        );
      }
      // console.log(dateRange);
      props.setValue(dateRange);
      props.setSchool([]);
      // props.setDensity('');
    } else {
      props.setSchool(selectedSchoolData);
      const startDate = selectedSchoolData[8]?.split('/').reverse().join('-') + ' 00:00:00';
      const endDate = selectedSchoolData[9]?.split('/').reverse().join('-') + ' 23:59:59';

      props.setValue([new Date(startDate), new Date(endDate)]);
      // props.setDensity(selectedDensity);
    }
    handleClose();
  };

  const isAllSelected =
    filterType === 'time'
      ? true
      : selectedCity !== '' && selectedArea !== '' && selectedSubArea !== '' && selectedSchool !== '' && selectedDensity.length !== 0
      ? true
      : false;

  const selectedCityData = schoolData.find((city) => city.name === selectedCity);
  const selectedAreaData = selectedCityData?.areas.find((area) => area.name === selectedArea);
  const selectedSubAreaData = selectedAreaData?.subareas.find((subarea) => subarea.name === selectedSubArea);
  const selectedSchoolData = selectedSubAreaData?.schools.find((school) => school[1] === selectedSchool);
  // console.log(selectedSchoolData);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleTerminate = () => {
    setAnchorEl(null);
  };

  const state_open = Boolean(anchorEl);
  const id = state_open ? 'simple-popover' : undefined;

  const buttonCss = {
    m: '0.5em 0 0 0.75em',
    p: '2px 1em',
    fontWeight: 'bold',
    fontSize: '0.8em',
    borderRadius: '1.5em',
  };
  const ITEM_HEIGHT = 39;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Box width='100%' m='0 15px' display='flex' flexDirection={'column'} sx={{ borderRadius: '10px' }}>
      <Box position={'absolute'} top={0} right={0}>
        <Button onClick={handleClick} sx={{ width: '32px', minWidth: '32px', color: colors.grey[400] }}>
          <HelpIcon />
        </Button>
        <Popover
          id={id}
          open={state_open}
          anchorEl={anchorEl}
          onClose={handleTerminate}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          slotProps={{
            paper: {
              style: { width: '250px' },
            },
          }}>
          <Typography fontSize={'16px'} sx={{ p: 2 }}>
            <InfoIcon sx={{ mr: '10px', verticalAlign: 'text-top' }} />
            <strong>{strLang.FILTER_TITLE}</strong>
            <br />
            {strLang.FILTER_DESC}
          </Typography>
        </Popover>
      </Box>
      <Box width={'100%'} display={'flex'} mb={'0.25em'}>
        <Box>
          <FilterIcon sx={{ color: colors.greenAccent[300], fontSize: '32px' }} />
        </Box>
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <Typography variant='h4' sx={{ color: colors.grey[100] }}>
            {strLang.FILTER_LABEL}
          </Typography>
        </Box>
        <Box>
          <Typography variant='h4' sx={{ color: colors.grey[100] }} mt={'-3px'}>
            {strLang.FILTER_SELECT}
          </Typography>
        </Box>
      </Box>

      <Box display='flex' justifyContent='space-between' mt='2px'>
        <Box>
          <Typography variant='h5' sx={{ color: colors.greenAccent[300] }}>
            {filterType === 'time' ? strLang.TIME_FILTER_LABEL : strLang.LOCATION_FILTER_LABEL}
          </Typography>
        </Box>
        <Box mt='-5px' sx={{ '& .MuiButton-startIcon > *:nth-of-type(1)': { fontSize: '16px' } }}>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => handleOpen('time')}
            sx={buttonCss}
            startIcon={<TimerIcon sx={{ fontSize: '13px !important' }} />}>
            {strLang.SW_TIME}
          </Button>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => handleOpen('location')}
            sx={buttonCss}
            startIcon={<LocationIcon sx={{ fontSize: '13px !important' }} />}>
            {strLang.SW_LOCATION}
          </Button>
        </Box>
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth='md'>
        <DialogTitle
          sx={{ textTransform: 'uppercase', fontSize: '120%' }}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          {strLang.SW_POSTTEXT} {filterType === 'time' ? strLang.TIME_FILTER_LABEL : strLang.LOCATION_FILTER_LABEL}
          <Button
            sx={{ color: colors.greenAccent[300], fontSize: '80%' }}
            onClick={() => handleSwitchFilter(filterType === 'time' ? 'location' : 'time')}>
            ({strLang.SWITCH_TO} {filterType === 'time' ? strLang.SW_LOCATION : strLang.SW_TIME} {strLang.SW_POSTTEXT})
          </Button>
        </DialogTitle>
        <DialogContent>
          {filterType === 'time' ? (
            <Box minHeight={'350px'} width={'350px'}>
              <DateTimeRangePicker
                onChange={setDateRange}
                onInvalidChange={() => alert(language === 'en' ? 'Please select a suitable time range' : 'Vui lòng chọn thời điểm phù hợp')}
                value={dateRange}
                minDate={
                  JSON.parse(localStorage.getItem('user'))?.username === process.env.REACT_APP_SUPER_ADMIN
                    ? new Date('2024-05-09 00:00:00')
                    : new Date('2024-09-09 00:00:00')
                }
                disableClock
                clearIcon={null}
                isCalendarOpen={true}
                calendarIcon={<DateRangeIcon />}
                shouldOpenWidgets={({ reason, widget }) => reason === 'focus' && widget === 'calendar'}
              />
            </Box>
          ) : (
            <Box
              minHeight={'280px'}
              maxWidth={'350px'}
              width={'350px'}
              sx={{
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[300], // Replace 'blue' with your desired focused color
                },
                '& .MuiOutlinedInput-root.Mui-focused:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'gray', // Set the default color here
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.greenAccent[300], // Change the label font color when focused
                },
                '& .MuiCheckbox-root.Mui-checked:not(.Mui-disabled)': {
                  color: colors.greenAccent[300], // Change the label font color when focused
                },
                '& .MuiCheckbox-root.Mui-checked:not(.Mui-disabled) ~ .MuiFormControlLabel-label': {
                  fontWeight: 'bold',
                  color: colors.greenAccent[300], // Change the label font color when focused
                },
              }}>
              <FormControl fullWidth sx={{ m: '10px 0' }}>
                <InputLabel id='city-label'>{strLang.CITY}</InputLabel>
                <Select value={selectedCity} onChange={handleCityChange} labelId='city-lable' label={strLang.CITY} id='city-input'>
                  {schoolData.map((city) => (
                    <MenuItem key={city.id} value={city.name}>
                      {city.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mb: '10px' }} disabled={!selectedCity}>
                <InputLabel id='area-label'>{strLang.AREA}</InputLabel>
                <Select value={selectedArea} onChange={handleAreaChange} labelId='area-label' label={strLang.AREA} id='area-input'>
                  {selectedCityData?.areas.map((area) => (
                    <MenuItem key={area.id} value={area.name}>
                      {area.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mb: '10px' }} disabled={!selectedCity}>
                <InputLabel id='subarea-label'>{strLang.SUB_AREA}</InputLabel>
                <Select
                  labelId='subarea-label'
                  label={strLang.SUB_AREA}
                  id='subarea-input'
                  multiple
                  value={selectedSubArea}
                  onChange={handleSubAreaChange}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                  input={<OutlinedInput label='Tag' />}>
                  {selectedAreaData?.subareas.map((subarea) => (
                    <MenuItem key={subarea.id} value={subarea.name} sx={{ py: 0 }}>
                      <Checkbox checked={selectedSubArea.includes(subarea.name)} />
                      <ListItemText primary={subarea.name} />
                    </MenuItem>
                  ))}
                  <MenuItem value={'Tất cả'} sx={{ p: 0, borderTop: 'solid 1px #666', mx: '15px' }} disabled>
                    <Checkbox checked={selectedSubArea.includes('Tất cả')} />
                    <ListItemText primary={'Tất cả'} />
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mb: '10px' }} disabled={!selectedArea}>
                <InputLabel id='school-label'>{strLang.SCHOOL}</InputLabel>
                <Select
                  value={selectedSchool}
                  onChange={handleSchoolChange}
                  labelId='school-label'
                  label={strLang.SCHOOL}
                  id='school-input'>
                  {selectedSubAreaData?.schools.map((school) => (
                    <MenuItem key={school[0]} value={school[1]}>
                      {school[1]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant='h6' mb={'8px'} display={'none'}>
                {strLang.DENSITY}
              </Typography>
              <FormControl component='fieldset' fullWidth sx={{ mb: '10px', display: 'none' }}>
                <FormGroup>
                  <Grid container spacing={'0.5'}>
                    {densityOptions.slice(0, 4).map((option) => (
                      <Grid item xs={6} key={option}>
                        <FormControlLabel
                          control={<Checkbox checked={selectedDensity.includes(option)} onChange={handleDensityChange} value={option} />}
                          label={option}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <FormControlLabel
                    sx={{ mt: '2px' }}
                    control={<Checkbox checked={selectedDensity.includes('All')} onChange={handleDensityChange} value='All' />}
                    label={language === 'en' ? 'All' : 'Tất cả'}
                  />
                </FormGroup>
              </FormControl>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mt: '-10px', p: '15px' }}>
          <Button variant='outlined' color='primary' onClick={handleClose} sx={{ color: colors.grey[100], fontSize: '13px' }}>
            {strLang.CANCEL}
          </Button>
          <Button disabled={!isAllSelected} variant='contained' color='success' onClick={handleConfirm} sx={{ fontSize: '12px' }}>
            {strLang.CONFIRM}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SteppedFilter;
// <Box width={'100%'} mb='15px' display={'flex'} alignItems={'center'}>
//   <FilterIcon sx={{ color: colors.greenAccent[300], fontSize: '26px' }} />
//   <Typography fontSize={'125%'} fontWeight={'bold'} m='0 10px 0 5px'>
//     {strLang.FILTER_LABEL}
//   </Typography>
//   <Button variant='contained' color='secondary' onClick={() => handleOpen('time')} sx={buttonCss}>
//     <strong>{strLang.TIME_FILTER}</strong>
//   </Button>
//   <Button variant='contained' color='secondary' onClick={() => handleOpen('location')} sx={buttonCss}>
//     <strong>{strLang.LOCATION_FILTER}</strong>
//   </Button>
// </Box>
// <Box width={'100%'}>
//   <Box>
//     <Typography fontSize={'1em'}>
//       <strong>{selectedTypeText}</strong>
//       {/* {selectedText} */}
//     </Typography>
//   </Box>
// </Box>
