export const phuThienCoord = {
  center: [13.529998, 108.312757], //giao nhau của QL25 Hùng Vương và đại lộ Nguyễn Tất Thành)
  sW: [13.340785, 108.205751],
  nE: [13.673018, 108.437942],
};

export const densityColor = ['#22aedd', '#32cd32', '#ffd700', '#ffa500', '#ff4500'];
export const pathColor = [
  '#ea5545',
  '#f46a9b',
  '#ef9b20',
  '#edbf33',
  '#ede15b',
  '#bdcf32',
  '#87bc45',
  '#27aeef',
  '#b33dc6',
  '#e60049',
  '#0bb4ff',
  '#50e991',
  '#e6d800',
  '#9b19f5',
  '#ffa300',
  '#dc0ab4',
  '#b3d4ff',
  '#00bfa0',
];
export const nodes = [
  {
    schoolID: 1, //Quang Trung
    startDate: '16/09/2024',
    endDate: '20/09/2024',
    ids: [
      206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 231, 233, 234, 235, 236, 237, 238,
      239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250, 256, 258, 259, 263, 265, 271, 272, 273, 274, 275, 277, 278, 279, 280, 281,
      282, 283, 284, 292, 303,
    ],
  },
  {
    schoolID: 2, //Nguyễn Bá ngọc
    startDate: '16/09/2024',
    endDate: '20/09/2024',
    ids: [
      6, 12, 13, 19, 20, 22, 31, 38, 41, 42, 47, 49, 55, 56, 57, 58, 59, 65, 67, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 81, 82, 83, 84,
      85, 86, 87, 88, 89, 90, 92, 93, 94, 95, 96, 97, 98, 99, 156, 158, 159, 169, 170, 174, 188, 189, 196, 251, 252, 253, 254, 255, 287,
      288, 290, 293, 296, 297,
    ],
  },
  {
    schoolID: 3, //Hoàng Hoa Thám
    startDate: '16/09/2024',
    endDate: '20/09/2024',
    ids: [
      1, 3, 4, 5, 7, 8, 10, 15, 16, 18, 21, 23, 24, 26, 28, 29, 30, 32, 33, 35, 36, 37, 39, 40, 43, 44, 45, 46, 48, 50, 51, 53, 54, 62, 63,
      64, 66, 68, 104, 151, 153, 154, 160, 171, 172, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 190, 191, 192, 193, 194,
      195, 197, 198, 200, 289,
    ],
  },
  {
    schoolID: 4, //Nguyễn Trung Trực
    startDate: '23/09/2024',
    endDate: '27/09/2024',
    ids: [
      1, 3, 4, 5, 6, 12, 14, 19, 20, 22, 23, 24, 26, 31, 33, 37, 38, 41, 42, 47, 48, 49, 50, 53, 54, 55, 56, 57, 58, 59, 62, 64, 66, 67, 69,
      70, 71, 72, 73, 74, 75, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 92, 94, 95, 97, 98, 99, 101, 103, 104, 105, 106, 107,
      108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134,
      135, 136, 138, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 156, 158, 161, 162, 163, 165, 166, 167, 168, 170, 173, 174, 189,
      196, 201, 202, 203, 204, 205, 226, 227, 228, 229, 230, 251, 252, 253, 266, 267, 269, 270, 286, 288, 290, 291, 292, 293, 294, 295, 298,
      299, 300,
    ],
  },
  {
    schoolID: 5, //Trưng Vương
    startDate: '23/09/2024',
    endDate: '27/09/2024',
    ids: [
      7, 8, 10, 15, 16, 18, 21, 25, 28, 30, 32, 35, 36, 39, 40, 43, 44, 45, 46, 51, 63, 68, 76, 96, 139, 151, 153, 154, 159, 160, 169, 172,
      176, 177, 178, 179, 180, 181, 183, 184, 185, 186, 187, 188, 190, 191, 192, 193, 194, 195, 197, 198, 200, 206, 207, 208, 209, 210, 211,
      212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243,
      245, 246, 247, 248, 249, 250, 254, 255, 258, 259, 260, 261, 262, 263, 264, 265, 271, 272, 273, 274, 276, 277, 278, 279, 280, 281, 282,
      283, 284, 285, 287, 289,
    ],
  },
  {
    schoolID: 6, //Lê Quý Đôn
    startDate: '30/09/2024',
    endDate: '04/10/2024',
    ids: [
      1, 3, 4, 5, 6, 7, 8, 9, 12, 13, 14, 15, 16, 18, 19, 20, 21, 22, 23, 24, 25, 26, 28, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
      42, 43, 44, 45, 46, 47, 49, 50, 51, 52, 53, 54, 56, 57, 58, 59, 61, 62, 63, 64, 65, 66, 68, 69, 70, 71, 73, 74, 75, 76, 77, 78, 79,
      80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 92, 94, 95, 96, 97, 98, 99, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
      113, 114, 115, 116, 117, 118, 119, 120, 121, 260, 263, 264, 265, 266, 269, 270, 277, 278,
    ],
  },
  {
    schoolID: 7, //Ngô Gia Tự
    startDate: '30/09/2024',
    endDate: '04/10/2024',
    ids: [
      55, 122, 123, 124, 125, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 139, 140, 141, 142, 143, 146, 148, 149, 150, 151, 153,
      154, 156, 158, 159, 160, 162, 163, 165, 166, 167, 169, 170, 171, 172, 174, 176, 177, 178, 179, 180, 181, 182, 183, 184, 186, 187, 188,
      189, 192, 194, 195, 196, 197, 198, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 213, 214, 215, 216, 217, 218, 219, 220, 221,
      222, 223, 224, 225, 226, 227, 229, 230, 232, 234, 236, 237, 239, 240, 241, 243, 244, 245, 246, 248, 249, 250, 251, 254, 258, 259, 272,
      274, 275, 279, 281, 283, 284, 287, 290, 291,
    ],
  },
  {
    schoolID: 8, //Nguyễn Du
    startDate: '07/10/2024',
    endDate: '11/10/2024',
    ids: [
      1, 3, 4, 5, 6, 7, 9, 10, 12, 13, 14, 16, 19, 20, 21, 22, 23, 24, 26, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
      44, 45, 46, 47, 49, 50, 51, 52, 53, 54, 56, 57, 58, 59, 61, 62, 64, 66, 68, 69, 70, 71, 72, 74, 75, 77, 78, 79, 80, 81, 82, 83, 84,
      85, 86, 87, 89, 90, 92, 94, 95, 96, 97, 98, 99, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117,
      118, 119, 120, 121, 147, 228, 263, 264, 265, 266, 269, 270, 277, 278,
    ],
  },
  {
    schoolID: 9, //Trường Chinh
    startDate: '07/10/2024',
    endDate: '11/10/2024',
    ids: [
      18, 48, 55, 65, 67, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 139, 140, 141, 142, 143, 144, 146,
      147, 148, 149, 150, 151, 154, 156, 158, 159, 160, 162, 163, 164, 166, 167, 169, 170, 171, 172, 174, 177, 178, 179, 180, 181, 182, 184,
      186, 187, 188, 189, 191, 192, 194, 195, 196, 197, 198, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 213, 214, 215, 216,
      218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 229, 230, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246,
      248, 249, 250, 251, 252, 253, 254, 255, 258, 259, 261, 272, 273, 274, 276, 279, 280, 281, 282, 283, 284, 287, 290, 291, 292, 293, 294,
      295, 296, 298, 299, 300, 302,
    ],
  },
];
export const truongTHCS_PT = [
  {
    id: 1,
    Ten: 'THCS Quang Trung',
    DiaChi: '121 Đường Trần Phú, TT. Phú Thiện, Phú Thiện, Gia Lai',
    Lat: 13.544549,
    Lon: 108.258993,
    SoHS: 327, //số học sinh của trường
    TLDXD: '49.5%', //tỉ lệ học sinh sử dụng xe đạp đến trường
    HSDXD: 162, //số bạn dùng xe đạp đi học (=SoHS * TLDXD)
    Pilot: 50, // số lượng HS được chọn tham gia pilot. (=HSDXD / Sum(All-HSDXD) * 1200)
    SDT: '383459747',
  },
  {
    id: 4,
    Ten: 'THCS Nguyễn Trung Trực',
    DiaChi: 'TT. Phú Thiện, Phú Thiện District, Gia Lai',
    Lat: 13.529546,
    Lon: 108.307839,
    SoHS: 606,
    TLDXD: '75.0%',
    HSDXD: 455,
    Pilot: 142,
    SDT: '987661936',
  },
  {
    id: 8,
    Ten: 'THCS Trường Chinh',
    DiaChi: '121 Đường Trần Phú, TT. Phú Thiện, Phú Thiện, Gia Lai',
    Lat: 13.436125,
    Lon: 108.408223,
    SoHS: 493,
    TLDXD: '90.0%',
    HSDXD: 444,
    Pilot: 138,
    SDT: '986938902',
  },
  {
    id: 10,
    Ten: 'THCS Nguyễn Tất Thành',
    DiaChi: '121 Đường Trần Phú, TT. Phú Thiện, Phú Thiện, Gia Lai',
    Lat: 13.495814,
    Lon: 108.391239,
    SoHS: 285,
    TLDXD: '75.0%',
    HSDXD: 214,
    Pilot: 670,
    SDT: '909646278',
  },
  {
    id: 11,
    Ten: 'THCS Trần Quốc Toản',
    DiaChi: '121 Đường Trần Phú, TT. Phú Thiện, Phú Thiện, Gia Lai',
    Lat: 13.52264,
    Lon: 108.317332,
    SoHS: 980,
    TLDXD: '68.0%',
    HSDXD: 666,
    Pilot: 208,
    SDT: '	986286168',
  },
  {
    id: 5,
    Ten: 'THCS Trưng Vương',
    DiaChi: '121 Đường Trần Phú, TT. Phú Thiện, Phú Thiện, Gia Lai',
    Lat: 13.510525,
    Lon: 108.34439,
    SoHS: 444,
    TLDXD: '99.3%',
    HSDXD: 441,
    Pilot: 138,
    SDT: '984909977',
  },
  {
    id: 6,
    Ten: 'THCS Lê Quý Đôn',
    DiaChi: '121 Đường Trần Phú, TT. Phú Thiện, Phú Thiện, Gia Lai',
    Lat: 13.482381,
    Lon: 108.374513,
    SoHS: 345,
    TLDXD: '93.3%',
    HSDXD: 322,
    Pilot: 100,
    SDT: '974108083',
  },
  {
    id: 9,
    Ten: 'THCS Nguyễn Du',
    DiaChi: '121 Đường Trần Phú, TT. Phú Thiện, Phú Thiện, Gia Lai',
    Lat: 13.444979,
    Lon: 108.408879,
    SoHS: 391,
    TLDXD: '71.9%',
    HSDXD: 281,
    Pilot: 88,
    SDT: '373259468',
  },
  {
    id: 2,
    Ten: 'PTDTBT THCS Nguuyễn Bá Ngọc',
    DiaChi: 'Xã Chư A Thai, Phú Thiện, Gia Lai',
    Lat: 13.565882,
    Lon: 108.308499,
    SoHS: 310,
    TLDXD: '90.0%',
    HSDXD: 279,
    Pilot: 87,
    SDT: '961919856',
  },
  {
    id: 7,
    Ten: 'THCS Ngô Gia Tự',
    DiaChi: 'Xã Ia Piar, Phú Thiện, Gia Lai',
    Lat: 13.495795,
    Lon: 108.366135,
    SoHS: 385,
    TLDXD: '97.3%',
    HSDXD: 375,
    Pilot: 117,
    SDT: '358270391',
  },
  {
    id: 3,
    Ten: 'THCS Hoàng Hoa Thám',
    DiaChi: 'Xã Ia Ake, Phú Thiện, Gia Lai',
    Lat: 13.537531,
    Lon: 108.278215,
    SoHS: 349,
    TLDXD: '59.9%',
    HSDXD: 209,
    Pilot: 65,
    SDT: '345271678',
  },
];

export const school_data = [
  {
    city: 'Gia Lai',
    areas: [
      {
        area: 'Huyện Phú Thiện',
        subareas: [
          {
            subarea: 'Thị trấn Phú Thiện ',
            schools: [
              { name: 'THCS Nguyễn Trung Trực', id: 2 },
              { name: 'THCS Trần Quốc Toản', id: 5 },
            ],
          },
          {
            subarea: 'Nơi khác thuộc Phú Thiện',
            schools: [
              { name: 'THCS Quang Trung', id: 1 },
              { name: 'THCS Hoàng Hoa Thám', id: 12 },
            ],
          },
        ],
      },
    ],
  },
  // {
  //   city: 'Hà Nội',
  //   areas: [
  //     {
  //       area: 'Quận Ba Đình',
  //       subareas: [
  //         {
  //           subarea: 'Phường Ngọc Khánh',
  //           schools: [
  //             { name: 'THCS Nguyễn Bá Ngọc', id: 12 },
  //             { name: 'THCS Lê Ngọc Hân', info: 15 },
  //           ],
  //         },
  //         {
  //           subarea: 'Phường Kim Mã',
  //           schools: [
  //             { name: 'THCS Phạm Hồng Thái', info: 11 },
  //             { name: 'THCS Thực Nghiệm', info: 13 },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       area: 'Quận Đống Đa',
  //       subareas: [
  //         {
  //           subarea: 'Phường Văn Miếu',
  //           schools: [
  //             { name: 'THCS Lý Thường Kiệt', id: 22 },
  //             { name: 'THCS Cát Linh', info: 25 },
  //           ],
  //         },
  //         {
  //           subarea: 'Phường Nguyễn Thái Học',
  //           schools: [
  //             { name: 'THCS Phan Chu Trinh', info: 21 },
  //             { name: 'THCS Hồng Hà', info: 23 },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const route_11 = {
  1: [{}, {}],
};

export const route_to_11 = {
  1: [
    { EW: 'E', Lat: 13.52265, Long: 108.31715, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52344, Long: 108.31717, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52352, Long: 108.31436, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52355, Long: 108.31282, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5236, Long: 108.31136, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52262, Long: 108.31105, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52123, Long: 108.31031, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52008, Long: 108.3095, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  2: [
    { EW: 'E', Lat: 13.52262, Long: 108.31717, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52152, Long: 108.31716, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52158, Long: 108.31515, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51724, Long: 108.31515, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  3: [
    { EW: 'E', Lat: 13.52258, Long: 108.31716, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52153, Long: 108.31714, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51965, Long: 108.31706, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51956, Long: 108.31824, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  4: [
    { EW: 'E', Lat: 13.52268, Long: 108.31717, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52214, Long: 108.31712, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52209, Long: 108.31903, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52147, Long: 108.31904, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52141, Long: 108.32097, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52056, Long: 108.32091, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  5: [
    { EW: 'E', Lat: 13.52266, Long: 108.31715, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52051, Long: 108.31707, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52055, Long: 108.31581, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52057, Long: 108.31434, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5216, Long: 108.31433, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  6: [
    { EW: 'E', Lat: 13.5226, Long: 108.31716, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52151, Long: 108.31716, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52156, Long: 108.31584, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52055, Long: 108.31581, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  7: [
    { EW: 'E', Lat: 13.52263, Long: 108.31715, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52344, Long: 108.31719, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52584, Long: 108.31728, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52806, Long: 108.31733, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52773, Long: 108.31571, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52736, Long: 108.31564, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  8: [
    { EW: 'E', Lat: 13.52258, Long: 108.31717, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52484, Long: 108.31723, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52584, Long: 108.31726, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5257, Long: 108.31834, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52568, Long: 108.31896, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52577, Long: 108.31933, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52543, Long: 108.32043, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52521, Long: 108.32201, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52578, Long: 108.32205, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  9: [
    { EW: 'E', Lat: 13.52257, Long: 108.31717, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52339, Long: 108.31719, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52349, Long: 108.31475, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5274, Long: 108.31483, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  10: [
    { EW: 'E', Lat: 13.52264, Long: 108.31715, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52339, Long: 108.31715, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52339, Long: 108.31873, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52406, Long: 108.3188, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52439, Long: 108.32032, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52699, Long: 108.32054, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  11: [
    { EW: 'E', Lat: 13.52257, Long: 108.31722, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52336, Long: 108.31719, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52346, Long: 108.31875, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52406, Long: 108.31879, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52437, Long: 108.32029, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52431, Long: 108.32176, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52523, Long: 108.322, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52488, Long: 108.32473, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  12: [
    { EW: 'E', Lat: 13.5226, Long: 108.31713, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52343, Long: 108.31715, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52333, Long: 108.32099, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52441, Long: 108.32099, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52439, Long: 108.32181, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52518, Long: 108.322, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52827, Long: 108.32217, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  13: [
    { EW: 'E', Lat: 13.52255, Long: 108.31707, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52337, Long: 108.31724, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52925, Long: 108.31745, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52984, Long: 108.31402, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53213, Long: 108.31365, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53337, Long: 108.31363, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53327, Long: 108.3126, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  14: [
    { EW: 'E', Lat: 13.52245, Long: 108.31717, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52347, Long: 108.31715, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52356, Long: 108.31346, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52752, Long: 108.31348, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52752, Long: 108.31166, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52737, Long: 108.31026, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  15: [
    { EW: 'E', Lat: 13.52341, Long: 108.31715, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52353, Long: 108.31284, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52746, Long: 108.31295, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52752, Long: 108.31159, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53025, Long: 108.31164, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53061, Long: 108.30889, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  16: [
    { EW: 'E', Lat: 13.52341, Long: 108.31715, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52347, Long: 108.31438, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52255, Long: 108.31441, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52257, Long: 108.31097, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52126, Long: 108.31022, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52015, Long: 108.3094, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52045, Long: 108.30876, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  17: [
    { EW: 'E', Lat: 13.5226, Long: 108.31713, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52153, Long: 108.31709, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52162, Long: 108.31434, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52057, Long: 108.31436, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5207, Long: 108.31189, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52132, Long: 108.31028, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52009, Long: 108.30945, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  18: [
    { EW: 'E', Lat: 13.52253, Long: 108.31711, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52153, Long: 108.31711, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51878, Long: 108.31702, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51821, Long: 108.317, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51942, Long: 108.31915, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52078, Long: 108.32121, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52203, Long: 108.3234, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52316, Long: 108.32522, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  19: [
    { EW: 'E', Lat: 13.52255, Long: 108.31715, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52149, Long: 108.31713, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51965, Long: 108.31707, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51838, Long: 108.31702, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51698, Long: 108.31522, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51604, Long: 108.31423, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5165, Long: 108.31305, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51696, Long: 108.31157, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51655, Long: 108.3114, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  20: [
    { EW: 'E', Lat: 13.52245, Long: 108.31722, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52153, Long: 108.31709, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52162, Long: 108.31432, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52057, Long: 108.31436, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52061, Long: 108.31187, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52057, Long: 108.31146, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51899, Long: 108.31084, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51924, Long: 108.31009, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51963, Long: 108.30915, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51924, Long: 108.30874, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
};
export const roads_1 = [];
export const roads_11 = [
  {
    id: 1,
    road: 'Trần Phú -1',
    central: [
      [13.52339, 108.31718],
      [13.52151, 108.31712],
    ],
    plot: [
      [13.52339, 108.31718],
      [13.52151, 108.31712],
    ],
    count: 18,
    ratio: 90,
    color: 5,
  },
  {
    id: 2,
    road: 'Trần Hưng Đạo -1',
    central: [
      [13.5234, 108.31718],
      [13.52355, 108.31288],
    ],
    plot: [
      [13.5234, 108.31718],
      [13.52355, 108.31288],
    ],
    count: 7,
    ratio: 35,
    color: 2,
  },
  {
    id: 3,
    road: 'Trần Hưng Đạo -2',
    central: [
      [13.5234, 108.31718],
      [13.5233, 108.32101],
    ],
    plot: [
      [13.5234, 108.31718],
      [13.5233, 108.32101],
    ],
    count: 6,
    ratio: 30,
    color: 2,
  },
  {
    id: 4,
    road: 'Trần Phú -2',
    central: [
      [13.52339, 108.31717],
      [13.52919, 108.31738],
    ],
    plot: [
      [13.52339, 108.31717],
      [13.52586, 108.31727],
      [13.52919, 108.31738],
    ],
    count: 8,
    ratio: 40,
    color: 3,
  },

  {
    id: 5,
    road: 'Trần Phú -3',
    central: [
      [13.52148, 108.31713],
      [13.51846, 108.31702],
    ],
    plot: [
      [13.52148, 108.31713],
      [13.51846, 108.31702],
    ],
    count: 4,
    ratio: 20,
    color: 2,
  },
  {
    id: 6,
    road: ' Lê Lợi -1',
    central: [
      [13.52148, 108.31713],
      [13.52156, 108.31517],
    ],
    plot: [
      [13.52148, 108.31713],
      [13.52156, 108.31517],
    ],
    count: 5,
    ratio: 65,
    color: 4,
  },
  {
    id: 7,
    road: 'Lê Lợi -2',
    central: [
      [13.52152, 108.31714],
      [13.52142, 108.32094],
    ],
    plot: [
      [13.52152, 108.31714],
      [13.52148, 108.31904],
      [13.52142, 108.32094],
    ],
    count: 3,
    ratio: 15,
    color: 1,
  },
];

export const route_to_3 = {
  1: [
    { EW: 'E', Lat: 13.53772, Long: 108.27808, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53772, Long: 108.27808, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53738, Long: 108.27699, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53816, Long: 108.27684, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53812, Long: 108.27483, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53885, Long: 108.27481, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53887, Long: 108.27364, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53884, Long: 108.2725, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  2: [
    { EW: 'E', Lat: 13.53772, Long: 108.27808, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53772, Long: 108.27808, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53784, Long: 108.27842, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53653, Long: 108.27871, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53693, Long: 108.28065, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5379, Long: 108.28055, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53802, Long: 108.28104, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53772, Long: 108.28111, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  3: [
    { EW: 'E', Lat: 13.53773, Long: 108.27808, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53775, Long: 108.27805, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53785, Long: 108.27844, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53652, Long: 108.27874, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  4: [
    { EW: 'E', Lat: 13.53773, Long: 108.27807, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53773, Long: 108.27807, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53742, Long: 108.277, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53727, Long: 108.27481, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5373, Long: 108.274, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53684, Long: 108.27401, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  5: [
    { EW: 'E', Lat: 13.53774, Long: 108.27807, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53774, Long: 108.27807, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5374, Long: 108.27699, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53818, Long: 108.27685, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53811, Long: 108.27482, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53844, Long: 108.27483, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  6: [
    { EW: 'E', Lat: 13.53773, Long: 108.27807, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53773, Long: 108.27807, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53743, Long: 108.27697, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53729, Long: 108.27482, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5373, Long: 108.2739, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53748, Long: 108.27247, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53798, Long: 108.27111, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53803, Long: 108.27092, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53696, Long: 108.27048, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  7: [
    { EW: 'E', Lat: 13.53777, Long: 108.27806, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53793, Long: 108.27863, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53904, Long: 108.2785, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54022, Long: 108.2783, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54047, Long: 108.27981, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  8: [
    { EW: 'E', Lat: 13.53775, Long: 108.27806, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5379, Long: 108.27871, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53905, Long: 108.27849, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54022, Long: 108.27831, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.542, Long: 108.27916, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54339, Long: 108.27936, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  9: [
    { EW: 'E', Lat: 13.53776, Long: 108.27804, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5379, Long: 108.27869, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53903, Long: 108.27851, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5397, Long: 108.28067, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53975, Long: 108.2811, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54105, Long: 108.28175, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54189, Long: 108.28228, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  10: [
    { EW: 'E', Lat: 13.5378, Long: 108.27818, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53826, Long: 108.27969, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53869, Long: 108.28095, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53888, Long: 108.28179, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54052, Long: 108.28217, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54129, Long: 108.28371, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54186, Long: 108.28374, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  11: [
    { EW: 'E', Lat: 13.5378, Long: 108.27814, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53783, Long: 108.27843, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53654, Long: 108.2787, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.537, Long: 108.28066, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5379, Long: 108.28054, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53817, Long: 108.28135, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53873, Long: 108.28124, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53892, Long: 108.28179, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53873, Long: 108.28369, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53858, Long: 108.2852, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  12: [
    { EW: 'E', Lat: 13.53773, Long: 108.27808, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53773, Long: 108.27808, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53728, Long: 108.27474, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53811, Long: 108.27483, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53809, Long: 108.27366, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53888, Long: 108.27366, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53956, Long: 108.27366, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  13: [
    { EW: 'E', Lat: 13.53749, Long: 108.27816, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53773, Long: 108.27806, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53717, Long: 108.27533, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53752, Long: 108.27223, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53798, Long: 108.27098, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53686, Long: 108.27046, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53725, Long: 108.26935, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5372, Long: 108.26918, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5366, Long: 108.26794, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  14: [
    { EW: 'E', Lat: 13.53749, Long: 108.27816, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53773, Long: 108.27806, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53735, Long: 108.27663, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53731, Long: 108.27381, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5375, Long: 108.27246, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53803, Long: 108.27256, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  15: [
    { EW: 'E', Lat: 13.53751, Long: 108.27822, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53751, Long: 108.27818, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53772, Long: 108.2781, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53741, Long: 108.27702, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53728, Long: 108.27476, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53808, Long: 108.27484, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53809, Long: 108.27452, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  16: [
    { EW: 'E', Lat: 13.53749, Long: 108.27825, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5375, Long: 108.27817, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53773, Long: 108.27808, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53791, Long: 108.27868, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53825, Long: 108.27972, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53866, Long: 108.28099, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.53921, Long: 108.28086, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
};

export const roads_3 = [
  {
    id: 1,
    road: 'QL25 -1',
    central: [
      [13.53729, 108.27478],
      [13.53742, 108.27697],
    ],
    plot: [
      [13.53742, 108.27697],
      [13.53732, 108.27647],
      [13.53729, 108.27576],
      [13.53729, 108.27478],
    ],
    count: 3,
    ratio: 18.75,
    color: 1,
  },
  {
    id: 2,
    road: 'QL25 -2',
    central: [
      [13.53794, 108.27868],
      [13.53741, 108.27697],
    ],
    plot: [
      [13.53794, 108.27868],
      [13.53741, 108.27697],
    ],
    count: 16,
    ratio: 100,
    color: 5,
  },
  {
    id: 3,
    road: 'QL25 -3',
    central: [
      [13.53794, 108.27868],
      [13.53884, 108.28181],
    ],
    plot: [
      [13.53794, 108.27868],
      [13.53843, 108.2802],
      [13.53875, 108.28121],
      [13.53889, 108.28179],
    ],
    count: 4,
    ratio: 25,
    color: 2,
  },
  {
    id: 4,
    road: 'Ngách 1',
    central: [
      [13.53742, 108.27699],
      [13.53819, 108.276831],
    ],
    plot: [
      [13.53742, 108.27699],
      [13.53819, 108.276831],
    ],
    count: 3,
    ratio: 18.75,
    color: 1,
  },
  {
    id: 5,
    road: 'Ngách 2',
    central: [
      [13.53903, 108.2785],
      [13.53796, 108.27868],
    ],
    plot: [
      [13.53796, 108.27868],
      [13.53903, 108.2785],
    ],
    count: 1,
    ratio: 6.25,
    color: 1,
  },
  {
    id: 6,
    road: 'Ngách 3',
    central: [
      [13.53785, 108.27846],
      [13.53652, 108.27873],
    ],
    plot: [
      [13.53785, 108.27846],
      [13.53652, 108.27873],
    ],
    count: 2,
    ratio: 12.5,
    color: 1,
  },
];
export const result_HHT = {
  'QL25 -1': {
    count: 3,
    routeIndices: [3, 5, 13],
  },
  'QL25 -2': {
    count: 16,
    routeIndices: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
  },
  'QL25 -3': {
    count: 4,
    routeIndices: [7, 9, 10, 15],
  },
  'Ngách 1': {
    count: 3,
    routeIndices: [0, 4, 5],
  },
  'Ngách 2': {
    count: 1,
    routeIndices: [8],
  },
  'Ngách 3': {
    count: 2,
    routeIndices: [1, 10],
  },
};

export const route_to_1 = {
  1: [
    { EW: 'E', Lat: 13.54454, Long: 108.25901, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54439, Long: 108.25905, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54454, Long: 108.25943, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54466, Long: 108.25986, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54473, Long: 108.26017, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54482, Long: 108.26044, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54484, Long: 108.26072, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54486, Long: 108.26091, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54496, Long: 108.26116, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54521, Long: 108.26098, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54535, Long: 108.2609, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54547, Long: 108.26105, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  2: [
    { EW: 'E', Lat: 13.54443, Long: 108.25904, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5445, Long: 108.25933, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54461, Long: 108.25971, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54468, Long: 108.25995, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54478, Long: 108.26046, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54484, Long: 108.26055, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54486, Long: 108.26061, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54475, Long: 108.26063, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  3: [
    { EW: 'E', Lat: 13.54454, Long: 108.25901, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54443, Long: 108.25909, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54463, Long: 108.2598, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54477, Long: 108.26033, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54487, Long: 108.26086, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54474, Long: 108.26105, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54453, Long: 108.26116, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5443, Long: 108.26133, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54411, Long: 108.26141, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54397, Long: 108.2615, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54381, Long: 108.26165, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54359, Long: 108.2618, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  4: [
    { EW: 'E', Lat: 13.54451, Long: 108.25904, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54439, Long: 108.25907, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54424, Long: 108.25846, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54388, Long: 108.25734, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  5: [
    { EW: 'E', Lat: 13.54454, Long: 108.259, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5444, Long: 108.25908, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54467, Long: 108.25999, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54479, Long: 108.26041, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54485, Long: 108.26089, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54496, Long: 108.26115, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54535, Long: 108.26093, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54576, Long: 108.26067, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  6: [
    { EW: 'E', Lat: 13.54455, Long: 108.25899, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54441, Long: 108.25907, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5446, Long: 108.2596, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54481, Long: 108.26054, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54488, Long: 108.26068, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54487, Long: 108.26094, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54631, Long: 108.25988, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  7: [
    { EW: 'E', Lat: 13.54455, Long: 108.25901, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54442, Long: 108.25905, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54462, Long: 108.25984, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5447, Long: 108.26018, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54484, Long: 108.26061, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5449, Long: 108.26113, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54507, Long: 108.26128, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5454, Long: 108.26184, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  8: [
    { EW: 'E', Lat: 13.54443, Long: 108.25907, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5446, Long: 108.25955, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54469, Long: 108.26011, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54486, Long: 108.26069, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54486, Long: 108.26092, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54503, Long: 108.26133, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54539, Long: 108.2619, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54582, Long: 108.26254, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54671, Long: 108.26199, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  9: [
    { EW: 'E', Lat: 13.54446, Long: 108.25906, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54474, Long: 108.26048, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54491, Long: 108.26096, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54515, Long: 108.26157, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5458, Long: 108.26259, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54754, Long: 108.26162, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  10: [
    { EW: 'E', Lat: 13.54443, Long: 108.25906, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54465, Long: 108.25985, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54482, Long: 108.26056, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54485, Long: 108.26092, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54445, Long: 108.26124, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54382, Long: 108.2616, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54382, Long: 108.2616, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5432, Long: 108.26207, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54256, Long: 108.2624, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  11: [
    { EW: 'E', Lat: 13.54454, Long: 108.259, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54442, Long: 108.25908, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5446, Long: 108.25961, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54478, Long: 108.2605, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54487, Long: 108.26075, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54487, Long: 108.261, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54521, Long: 108.26156, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54586, Long: 108.2626, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54536, Long: 108.26291, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54467, Long: 108.26331, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54388, Long: 108.26384, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  12: [
    { EW: 'E', Lat: 13.54441, Long: 108.2591, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54454, Long: 108.25947, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54478, Long: 108.26033, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54484, Long: 108.2609, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54497, Long: 108.26117, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54537, Long: 108.26093, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54681, Long: 108.26, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54681, Long: 108.26, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54705, Long: 108.26036, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54716, Long: 108.26029, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  13: [
    { EW: 'E', Lat: 13.54453, Long: 108.259, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54453, Long: 108.259, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54442, Long: 108.25906, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54432, Long: 108.25872, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54418, Long: 108.25825, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54408, Long: 108.25782, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54391, Long: 108.25736, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54347, Long: 108.25758, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54306, Long: 108.25778, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  14: [
    { EW: 'E', Lat: 13.5444, Long: 108.25904, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54384, Long: 108.25739, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54262, Long: 108.25803, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.541, Long: 108.25909, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  15: [
    { EW: 'E', Lat: 13.54438, Long: 108.25904, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54453, Long: 108.25958, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54474, Long: 108.26022, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54481, Long: 108.26091, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54497, Long: 108.26132, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54581, Long: 108.26267, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54675, Long: 108.26199, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54755, Long: 108.26159, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54874, Long: 108.26126, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  16: [
    { EW: 'E', Lat: 13.54435, Long: 108.25911, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54459, Long: 108.2599, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54483, Long: 108.2609, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54508, Long: 108.26149, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54553, Long: 108.26225, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54582, Long: 108.2626, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54674, Long: 108.2619, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54748, Long: 108.26157, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  17: [
    { EW: 'E', Lat: 13.5444, Long: 108.25905, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54447, Long: 108.25955, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54482, Long: 108.26076, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54483, Long: 108.26098, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54522, Long: 108.26155, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54583, Long: 108.26259, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54633, Long: 108.26222, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54683, Long: 108.26192, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5478, Long: 108.2615, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54872, Long: 108.26122, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54951, Long: 108.26108, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5494, Long: 108.26079, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54948, Long: 108.26052, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  18: [
    { EW: 'E', Lat: 13.54452, Long: 108.25901, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54437, Long: 108.25907, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54448, Long: 108.25944, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54481, Long: 108.26093, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54499, Long: 108.26119, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54391, Long: 108.26183, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  19: [
    { EW: 'E', Lat: 13.5445, Long: 108.25899, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54435, Long: 108.25904, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54467, Long: 108.25979, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54481, Long: 108.26089, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54379, Long: 108.26167, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54277, Long: 108.2623, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54165, Long: 108.26296, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54125, Long: 108.26361, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  20: [
    { EW: 'E', Lat: 13.54437, Long: 108.25912, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54489, Long: 108.2609, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54506, Long: 108.26126, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5453, Long: 108.26177, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54579, Long: 108.2626, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54473, Long: 108.26328, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54332, Long: 108.26413, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54218, Long: 108.26527, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.54129, Long: 108.26647, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
};
export const route_to_2 = {
  1: [
    { EW: 'E', Lat: 13.56586, Long: 108.30852, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56557, Long: 108.30809, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56594, Long: 108.30774, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5663, Long: 108.30685, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56705, Long: 108.30712, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  2: [
    { EW: 'E', Lat: 13.56547, Long: 108.3082, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56589, Long: 108.30778, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56651, Long: 108.30631, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56766, Long: 108.30539, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56907, Long: 108.30442, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57032, Long: 108.30391, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57092, Long: 108.30372, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  3: [
    { EW: 'E', Lat: 13.56558, Long: 108.30808, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5649, Long: 108.30866, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56442, Long: 108.30905, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56426, Long: 108.30933, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56369, Long: 108.3098, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56416, Long: 108.31017, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56554, Long: 108.31116, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  4: [
    { EW: 'E', Lat: 13.56557, Long: 108.30818, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56459, Long: 108.30906, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56392, Long: 108.30956, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56365, Long: 108.30991, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56422, Long: 108.31028, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5656, Long: 108.31114, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56502, Long: 108.31212, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  5: [
    { EW: 'E', Lat: 13.56549, Long: 108.30823, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56427, Long: 108.30917, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56284, Long: 108.30799, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56258, Long: 108.30713, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56212, Long: 108.30651, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56247, Long: 108.306, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56279, Long: 108.30555, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56338, Long: 108.30564, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56358, Long: 108.30575, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56391, Long: 108.30535, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5642, Long: 108.30526, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56431, Long: 108.30454, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  6: [
    { EW: 'E', Lat: 13.56552, Long: 108.30809, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56499, Long: 108.30873, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56432, Long: 108.30911, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56361, Long: 108.30987, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56256, Long: 108.30943, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56206, Long: 108.31038, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56136, Long: 108.31153, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5617, Long: 108.31179, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  7: [
    { EW: 'E', Lat: 13.56554, Long: 108.30808, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56472, Long: 108.30899, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56419, Long: 108.30914, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56305, Long: 108.3083, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56281, Long: 108.30787, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56267, Long: 108.30734, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5621, Long: 108.30658, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56229, Long: 108.30617, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56282, Long: 108.30564, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56311, Long: 108.30557, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56355, Long: 108.30573, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56372, Long: 108.30551, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56393, Long: 108.30534, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  8: [
    { EW: 'E', Lat: 13.56555, Long: 108.30812, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56645, Long: 108.30648, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56722, Long: 108.30552, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56912, Long: 108.30423, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57094, Long: 108.30363, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57242, Long: 108.30217, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57426, Long: 108.29932, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57417, Long: 108.29837, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57353, Long: 108.29758, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57315, Long: 108.29732, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57252, Long: 108.29747, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  9: [
    { EW: 'E', Lat: 13.56564, Long: 108.30794, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56462, Long: 108.30899, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56418, Long: 108.30925, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56334, Long: 108.30827, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56276, Long: 108.30786, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56209, Long: 108.30648, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56272, Long: 108.3056, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56349, Long: 108.30578, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56389, Long: 108.30539, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56422, Long: 108.30535, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56426, Long: 108.30425, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5636, Long: 108.30391, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56349, Long: 108.30312, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  10: [
    { EW: 'E', Lat: 13.56546, Long: 108.30814, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56506, Long: 108.30859, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56458, Long: 108.30899, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56434, Long: 108.30916, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56369, Long: 108.30988, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56424, Long: 108.31022, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56505, Long: 108.31075, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56578, Long: 108.31117, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56639, Long: 108.31142, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  11: [
    { EW: 'E', Lat: 13.56563, Long: 108.30812, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56514, Long: 108.30862, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56463, Long: 108.30895, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56369, Long: 108.30991, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56557, Long: 108.31119, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56641, Long: 108.31138, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56724, Long: 108.31056, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  12: [
    { EW: 'E', Lat: 13.56566, Long: 108.30803, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56483, Long: 108.30879, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56384, Long: 108.30952, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56369, Long: 108.30989, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56427, Long: 108.31026, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56293, Long: 108.31241, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56523, Long: 108.31381, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56566, Long: 108.31431, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  13: [
    { EW: 'E', Lat: 13.56563, Long: 108.30798, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56475, Long: 108.3089, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56402, Long: 108.30946, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56366, Long: 108.30991, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56251, Long: 108.3094, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.562, Long: 108.31029, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56136, Long: 108.31149, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56168, Long: 108.31173, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56162, Long: 108.31216, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  14: [
    { EW: 'E', Lat: 13.56576, Long: 108.3079, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5651, Long: 108.30849, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56467, Long: 108.30902, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56434, Long: 108.30918, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56325, Long: 108.30821, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56272, Long: 108.30759, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56256, Long: 108.30698, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56215, Long: 108.30649, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56247, Long: 108.30601, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56283, Long: 108.30554, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56356, Long: 108.30574, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56385, Long: 108.30539, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  15: [
    { EW: 'E', Lat: 13.56571, Long: 108.30804, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56498, Long: 108.30867, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56425, Long: 108.30923, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56367, Long: 108.3099, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56257, Long: 108.3094, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  16: [
    { EW: 'E', Lat: 13.56562, Long: 108.30809, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56598, Long: 108.30758, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56641, Long: 108.30661, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56692, Long: 108.30586, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56818, Long: 108.30494, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56922, Long: 108.30421, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57029, Long: 108.30388, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57101, Long: 108.30372, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57052, Long: 108.30231, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56986, Long: 108.30158, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  17: [
    { EW: 'E', Lat: 13.56585, Long: 108.30855, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5655, Long: 108.30813, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56627, Long: 108.30693, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56673, Long: 108.30596, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56806, Long: 108.30494, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56909, Long: 108.3044, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57003, Long: 108.30518, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  18: [
    { EW: 'E', Lat: 13.56558, Long: 108.30799, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56606, Long: 108.30721, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56641, Long: 108.30629, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56712, Long: 108.30541, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56827, Long: 108.30479, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56906, Long: 108.30436, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57025, Long: 108.30387, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57098, Long: 108.30357, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57209, Long: 108.30249, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.57363, Long: 108.30035, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  19: [
    { EW: 'E', Lat: 13.56574, Long: 108.30781, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56485, Long: 108.30882, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56412, Long: 108.30936, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56366, Long: 108.31002, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56247, Long: 108.30934, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56128, Long: 108.30835, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56044, Long: 108.30792, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.55994, Long: 108.30923, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5599, Long: 108.3102, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  20: [
    { EW: 'E', Lat: 13.56558, Long: 108.3082, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56453, Long: 108.30917, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56351, Long: 108.30987, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56405, Long: 108.3103, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5656, Long: 108.3111, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56645, Long: 108.31144, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56762, Long: 108.31045, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.56904, Long: 108.31005, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.572, Long: 108.31067, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5733, Long: 108.31078, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
};
export const route_to_5 = {
  1: [
    { EW: 'E', Lat: 13.51065, Long: 108.34468, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50952, Long: 108.3438, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50858, Long: 108.34262, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50764, Long: 108.34183, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50683, Long: 108.33955, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50574, Long: 108.3371, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50497, Long: 108.33629, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  2: [
    { EW: 'E', Lat: 13.51017, Long: 108.34466, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51073, Long: 108.34537, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51188, Long: 108.34689, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51284, Long: 108.34785, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51213, Long: 108.34869, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51036, Long: 108.35103, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50933, Long: 108.35255, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51034, Long: 108.35279, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51088, Long: 108.35273, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  3: [
    { EW: 'E', Lat: 13.51021, Long: 108.34468, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51088, Long: 108.34577, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51171, Long: 108.34663, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5124, Long: 108.34766, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51276, Long: 108.348, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51344, Long: 108.34856, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51424, Long: 108.34944, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51472, Long: 108.35015, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51518, Long: 108.35094, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51595, Long: 108.35243, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51676, Long: 108.35429, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  4: [
    { EW: 'E', Lat: 13.51029, Long: 108.34472, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51115, Long: 108.3459, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5119, Long: 108.34693, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5128, Long: 108.34781, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51597, Long: 108.34386, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51923, Long: 108.33906, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52014, Long: 108.33781, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  5: [
    { EW: 'E', Lat: 13.51029, Long: 108.34468, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51121, Long: 108.3459, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51215, Long: 108.34734, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51278, Long: 108.34783, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51587, Long: 108.3438, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5187, Long: 108.33992, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52046, Long: 108.33773, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5214, Long: 108.33603, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  6: [
    { EW: 'E', Lat: 13.51032, Long: 108.34487, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50873, Long: 108.34283, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50762, Long: 108.34187, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50723, Long: 108.34011, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50533, Long: 108.33659, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50462, Long: 108.33595, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50251, Long: 108.33425, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50357, Long: 108.33071, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  7: [
    { EW: 'E', Lat: 13.51032, Long: 108.34487, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50873, Long: 108.34283, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50762, Long: 108.34187, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50723, Long: 108.34011, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50533, Long: 108.33659, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50462, Long: 108.33595, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50251, Long: 108.33425, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50357, Long: 108.33071, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  8: [
    { EW: 'E', Lat: 13.51025, Long: 108.34446, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50869, Long: 108.34296, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50764, Long: 108.34187, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50666, Long: 108.33901, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50508, Long: 108.33659, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50261, Long: 108.33442, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50357, Long: 108.33088, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50441, Long: 108.33095, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50424, Long: 108.32773, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50395, Long: 108.32655, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50309, Long: 108.32592, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50378, Long: 108.32412, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  9: [
    { EW: 'E', Lat: 13.51038, Long: 108.3447, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50875, Long: 108.34307, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50769, Long: 108.34161, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50535, Long: 108.33646, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50251, Long: 108.3344, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50122, Long: 108.33331, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50119, Long: 108.33219, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50195, Long: 108.32996, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50314, Long: 108.32614, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50385, Long: 108.32359, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  10: [
    { EW: 'E', Lat: 13.51031, Long: 108.34472, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51096, Long: 108.34577, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51278, Long: 108.34783, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51063, Long: 108.35075, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50877, Long: 108.35395, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50929, Long: 108.35453, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51033, Long: 108.35466, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  11: [
    { EW: 'E', Lat: 13.50998, Long: 108.34457, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51079, Long: 108.34551, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51271, Long: 108.34777, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51574, Long: 108.34418, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51668, Long: 108.34298, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51553, Long: 108.34225, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51493, Long: 108.34161, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51501, Long: 108.34069, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  12: [
    { EW: 'E', Lat: 13.51042, Long: 108.34448, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51013, Long: 108.34466, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51288, Long: 108.34781, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5152, Long: 108.35084, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51576, Long: 108.35225, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.52121, Long: 108.34929, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  13: [
    { EW: 'E', Lat: 13.5105, Long: 108.34442, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51017, Long: 108.3447, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51111, Long: 108.34577, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51271, Long: 108.34783, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51044, Long: 108.3509, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50837, Long: 108.3544, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50676, Long: 108.35858, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50614, Long: 108.36015, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50716, Long: 108.36032, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  14: [
    { EW: 'E', Lat: 13.51042, Long: 108.34479, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51278, Long: 108.348, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51042, Long: 108.35105, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50793, Long: 108.35571, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50666, Long: 108.3592, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50783, Long: 108.35951, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50917, Long: 108.35963, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  15: [
    { EW: 'E', Lat: 13.51048, Long: 108.34459, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51013, Long: 108.34479, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51202, Long: 108.34708, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51298, Long: 108.34783, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51117, Long: 108.34991, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50923, Long: 108.35268, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50718, Long: 108.35715, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50618, Long: 108.36026, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50539, Long: 108.36219, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50574, Long: 108.36223, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  16: [
    { EW: 'E', Lat: 13.51046, Long: 108.34451, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51013, Long: 108.34464, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51148, Long: 108.34603, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51282, Long: 108.34796, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51215, Long: 108.34884, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51061, Long: 108.35058, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50894, Long: 108.35318, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50764, Long: 108.35592, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50675, Long: 108.35863, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50666, Long: 108.35918, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50779, Long: 108.35951, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50927, Long: 108.35953, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51159, Long: 108.35989, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  17: [
    { EW: 'E', Lat: 13.51044, Long: 108.34451, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51021, Long: 108.34468, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51096, Long: 108.34569, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51225, Long: 108.34749, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51269, Long: 108.34794, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5139, Long: 108.34659, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51649, Long: 108.3432, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51852, Long: 108.34007, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51781, Long: 108.33946, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  18: [
    { EW: 'E', Lat: 13.51039, Long: 108.34468, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50963, Long: 108.34425, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50863, Long: 108.34253, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50792, Long: 108.34146, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50663, Long: 108.33919, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5055, Long: 108.33674, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50362, Long: 108.33507, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5017, Long: 108.33404, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50078, Long: 108.33313, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50241, Long: 108.32713, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.504, Long: 108.32314, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50725, Long: 108.31696, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50963, Long: 108.31434, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  19: [
    { EW: 'E', Lat: 13.50994, Long: 108.34473, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50723, Long: 108.34095, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50577, Long: 108.33773, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50456, Long: 108.33602, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5023, Long: 108.33452, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5008, Long: 108.3334, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5031, Long: 108.32559, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50681, Long: 108.31688, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5079, Long: 108.31628, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51783, Long: 108.31744, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51896, Long: 108.3149, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  20: [
    { EW: 'E', Lat: 13.5102, Long: 108.34468, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51102, Long: 108.34595, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51173, Long: 108.34666, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5126, Long: 108.34782, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51335, Long: 108.34846, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51425, Long: 108.34975, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51538, Long: 108.35116, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.51571, Long: 108.35232, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5164, Long: 108.35367, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
};
export const route_to_7 = {
  1: [
    { EW: 'E', Lat: 13.49634, Long: 108.36601, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49537, Long: 108.36633, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49521, Long: 108.36517, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.495, Long: 108.36298, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49448, Long: 108.36058, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49416, Long: 108.35923, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  2: [
    { EW: 'E', Lat: 13.4955, Long: 108.36627, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49515, Long: 108.36509, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49055, Long: 108.36706, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48893, Long: 108.36268, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48774, Long: 108.36352, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48661, Long: 108.36403, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48807, Long: 108.36798, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  3: [
    { EW: 'E', Lat: 13.4954, Long: 108.36639, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49592, Long: 108.36858, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49619, Long: 108.36931, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49696, Long: 108.36895, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49801, Long: 108.36854, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49909, Long: 108.3677, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50024, Long: 108.36665, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50128, Long: 108.36581, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50166, Long: 108.36594, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50204, Long: 108.36639, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  4: [
    { EW: 'E', Lat: 13.49548, Long: 108.36629, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49584, Long: 108.36815, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49627, Long: 108.3691, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.497, Long: 108.3689, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49782, Long: 108.36837, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49882, Long: 108.36781, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50028, Long: 108.36652, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50245, Long: 108.36466, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5032, Long: 108.36384, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  5: [
    { EW: 'E', Lat: 13.49567, Long: 108.36618, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49554, Long: 108.36721, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49596, Long: 108.3683, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49623, Long: 108.36931, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49801, Long: 108.36865, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50139, Long: 108.36594, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50289, Long: 108.36444, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50331, Long: 108.36504, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50348, Long: 108.36588, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50408, Long: 108.36764, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50483, Long: 108.36809, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  6: [
    { EW: 'E', Lat: 13.49534, Long: 108.36624, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49617, Long: 108.36925, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49531, Long: 108.36961, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49362, Long: 108.3703, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49327, Long: 108.37051, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  7: [
    { EW: 'E', Lat: 13.4954, Long: 108.36631, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49513, Long: 108.36515, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49049, Long: 108.3671, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49095, Long: 108.3682, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48968, Long: 108.36878, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48932, Long: 108.36736, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48792, Long: 108.368, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  8: [
    { EW: 'E', Lat: 13.49565, Long: 108.36624, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49529, Long: 108.3653, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49145, Long: 108.36659, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49041, Long: 108.36699, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4887, Long: 108.36268, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48772, Long: 108.36339, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48682, Long: 108.36399, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48797, Long: 108.36783, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48847, Long: 108.37041, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  9: [
    { EW: 'E', Lat: 13.49546, Long: 108.36618, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49565, Long: 108.36717, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49611, Long: 108.36908, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49652, Long: 108.36929, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49799, Long: 108.36845, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50264, Long: 108.36455, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50381, Long: 108.36307, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5031, Long: 108.36223, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50243, Long: 108.36193, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  10: [
    { EW: 'E', Lat: 13.49561, Long: 108.36633, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49567, Long: 108.36721, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49592, Long: 108.3683, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49613, Long: 108.36918, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4973, Long: 108.36869, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49861, Long: 108.36805, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50126, Long: 108.36584, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50155, Long: 108.36575, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50197, Long: 108.36612, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50212, Long: 108.36642, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50205, Long: 108.36762, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5022, Long: 108.36796, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50228, Long: 108.36848, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50184, Long: 108.36983, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50151, Long: 108.37154, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  11: [
    { EW: 'E', Lat: 13.49539, Long: 108.36629, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49504, Long: 108.36498, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49466, Long: 108.36049, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49387, Long: 108.35727, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49368, Long: 108.35605, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48942, Long: 108.34762, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  12: [
    { EW: 'E', Lat: 13.49542, Long: 108.36637, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49521, Long: 108.36515, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49043, Long: 108.3671, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49099, Long: 108.36815, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4903, Long: 108.3683, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48972, Long: 108.36863, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48822, Long: 108.36925, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48676, Long: 108.3697, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  13: [
    { EW: 'E', Lat: 13.49548, Long: 108.36635, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49515, Long: 108.36509, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49051, Long: 108.36708, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48876, Long: 108.36247, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48797, Long: 108.3635, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48628, Long: 108.36418, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  14: [
    { EW: 'E', Lat: 13.49548, Long: 108.36635, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49596, Long: 108.36845, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49634, Long: 108.36914, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4988, Long: 108.36794, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50301, Long: 108.36429, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50408, Long: 108.36285, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50448, Long: 108.36249, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  15: [
    { EW: 'E', Lat: 13.49571, Long: 108.36622, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49546, Long: 108.36635, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49583, Long: 108.36858, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49613, Long: 108.36916, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49684, Long: 108.36912, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50003, Long: 108.36706, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50297, Long: 108.36431, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50516, Long: 108.36167, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50546, Long: 108.36215, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  16: [
    { EW: 'E', Lat: 13.49548, Long: 108.36633, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49523, Long: 108.3653, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49047, Long: 108.36695, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49101, Long: 108.36824, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48955, Long: 108.36888, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4882, Long: 108.36923, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48738, Long: 108.36953, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  17: [
    { EW: 'E', Lat: 13.49577, Long: 108.36614, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49527, Long: 108.36631, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49508, Long: 108.36532, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49058, Long: 108.36699, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48888, Long: 108.3627, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48809, Long: 108.36354, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48642, Long: 108.36401, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48797, Long: 108.3679, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  18: [
    { EW: 'E', Lat: 13.49577, Long: 108.36614, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49527, Long: 108.36631, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49508, Long: 108.36532, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49058, Long: 108.36699, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48888, Long: 108.3627, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48809, Long: 108.36354, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48642, Long: 108.36401, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48797, Long: 108.3679, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  19: [
    { EW: 'E', Lat: 13.49575, Long: 108.36622, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49536, Long: 108.36637, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49613, Long: 108.36933, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49316, Long: 108.37058, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48926, Long: 108.37225, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4873, Long: 108.37283, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48669, Long: 108.36976, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48751, Long: 108.36946, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  20: [
    { EW: 'E', Lat: 13.4954, Long: 108.36627, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49627, Long: 108.36931, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49675, Long: 108.36912, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49728, Long: 108.37002, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49849, Long: 108.37152, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4987, Long: 108.37324, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49797, Long: 108.37302, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49726, Long: 108.37311, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49563, Long: 108.37395, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
};
export const route_to_6 = {
  1: [
    { EW: 'E', Lat: 13.48292, Long: 108.37448, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48238, Long: 108.37356, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48248, Long: 108.37296, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48261, Long: 108.37197, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48229, Long: 108.37118, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48196, Long: 108.3683, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48167, Long: 108.36605, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48503, Long: 108.36476, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  2: [
    { EW: 'E', Lat: 13.48231, Long: 108.37399, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48183, Long: 108.37496, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48075, Long: 108.37538, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.47968, Long: 108.37579, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.47772, Long: 108.37633, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.47582, Long: 108.37766, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.47428, Long: 108.37897, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  3: [
    { EW: 'E', Lat: 13.48252, Long: 108.37479, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48277, Long: 108.37469, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48237, Long: 108.37348, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48242, Long: 108.37324, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48257, Long: 108.37263, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48259, Long: 108.37197, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48256, Long: 108.37163, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48276, Long: 108.37155, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48321, Long: 108.37246, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  4: [
    { EW: 'E', Lat: 13.48233, Long: 108.37453, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48238, Long: 108.37479, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48175, Long: 108.37508, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48204, Long: 108.37585, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48216, Long: 108.3765, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48227, Long: 108.37698, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48245, Long: 108.3772, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48284, Long: 108.37844, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  5: [
    { EW: 'E', Lat: 13.48237, Long: 108.3744, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48237, Long: 108.3744, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48248, Long: 108.37468, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48248, Long: 108.37468, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48246, Long: 108.37481, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48204, Long: 108.37497, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48177, Long: 108.375, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48177, Long: 108.37501, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48148, Long: 108.37507, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48142, Long: 108.37464, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48142, Long: 108.37464, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48126, Long: 108.37424, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  6: [
    { EW: 'E', Lat: 13.48233, Long: 108.37444, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48238, Long: 108.37459, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48244, Long: 108.37476, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48222, Long: 108.37484, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48178, Long: 108.37506, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48116, Long: 108.37528, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48088, Long: 108.37529, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  7: [
    { EW: 'E', Lat: 13.48232, Long: 108.37441, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48238, Long: 108.37455, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48245, Long: 108.37478, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48186, Long: 108.375, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48149, Long: 108.37512, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48092, Long: 108.37537, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48025, Long: 108.3756, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  8: [
    { EW: 'E', Lat: 13.48247, Long: 108.37469, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48247, Long: 108.37469, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48284, Long: 108.37461, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48378, Long: 108.37425, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48356, Long: 108.37395, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48336, Long: 108.37338, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48307, Long: 108.37262, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48318, Long: 108.37248, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48292, Long: 108.3718, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48283, Long: 108.37159, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48252, Long: 108.37166, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48245, Long: 108.37098, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  9: [
    { EW: 'E', Lat: 13.48248, Long: 108.37478, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48528, Long: 108.3733, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4863, Long: 108.37337, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48693, Long: 108.37287, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48757, Long: 108.37277, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48979, Long: 108.37204, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.491, Long: 108.37609, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48981, Long: 108.37669, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  10: [
    { EW: 'E', Lat: 13.48245, Long: 108.37476, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48307, Long: 108.37446, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4847, Long: 108.37399, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48651, Long: 108.37324, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48737, Long: 108.37288, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48728, Long: 108.37198, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48703, Long: 108.37098, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48687, Long: 108.36978, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  11: [
    { EW: 'E', Lat: 13.48244, Long: 108.37483, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48438, Long: 108.37397, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48565, Long: 108.37348, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4872, Long: 108.37287, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48714, Long: 108.37157, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48697, Long: 108.37084, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  12: [
    { EW: 'E', Lat: 13.48226, Long: 108.37476, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48431, Long: 108.37408, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48748, Long: 108.3727, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49161, Long: 108.37116, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49662, Long: 108.36918, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49746, Long: 108.36884, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49883, Long: 108.36781, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  13: [
    { EW: 'E', Lat: 13.48244, Long: 108.37479, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48288, Long: 108.37456, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48451, Long: 108.37397, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48459, Long: 108.37395, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48471, Long: 108.3746, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48409, Long: 108.37491, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.484, Long: 108.37518, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  14: [
    { EW: 'E', Lat: 13.48245, Long: 108.3746, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48245, Long: 108.37472, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48256, Long: 108.37478, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48276, Long: 108.37464, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4825, Long: 108.37361, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48242, Long: 108.37337, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4826, Long: 108.3722, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48253, Long: 108.3716, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48283, Long: 108.37164, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  15: [
    { EW: 'E', Lat: 13.48252, Long: 108.3747, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48244, Long: 108.37477, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48273, Long: 108.37466, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48244, Long: 108.37344, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48258, Long: 108.37209, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48251, Long: 108.37131, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48225, Long: 108.37021, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48208, Long: 108.36864, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4819, Long: 108.36822, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48172, Long: 108.36593, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48503, Long: 108.36469, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  16: [
    { EW: 'E', Lat: 13.48249, Long: 108.37478, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48202, Long: 108.37494, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48145, Long: 108.37514, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.47918, Long: 108.37592, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.47869, Long: 108.37623, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.47794, Long: 108.37645, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  17: [
    { EW: 'E', Lat: 13.4825, Long: 108.37469, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48264, Long: 108.37472, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48295, Long: 108.37456, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4856, Long: 108.37364, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48744, Long: 108.37284, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48706, Long: 108.37085, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48685, Long: 108.36974, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48745, Long: 108.36949, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48829, Long: 108.36917, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  18: [
    { EW: 'E', Lat: 13.48267, Long: 108.37483, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48127, Long: 108.37528, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.47954, Long: 108.37573, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.47522, Long: 108.37802, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.47407, Long: 108.3791, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.47344, Long: 108.37736, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  19: [
    { EW: 'E', Lat: 13.48227, Long: 108.37455, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48223, Long: 108.37483, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48302, Long: 108.37468, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48263, Long: 108.3738, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48234, Long: 108.37311, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48254, Long: 108.37242, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48244, Long: 108.37154, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48223, Long: 108.36998, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48198, Long: 108.36854, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48179, Long: 108.36796, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48165, Long: 108.36614, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4849, Long: 108.36474, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48662, Long: 108.36431, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48766, Long: 108.36371, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48887, Long: 108.36255, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4905, Long: 108.36699, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49096, Long: 108.36822, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  20: [
    { EW: 'E', Lat: 13.48236, Long: 108.37446, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48582, Long: 108.3735, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4887, Long: 108.37229, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49119, Long: 108.37122, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49198, Long: 108.37103, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49102, Long: 108.36815, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49052, Long: 108.36699, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
};

export const route_to_9 = {
  1: [
    { EW: 'E', Lat: 13.4451, Long: 108.40908, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4448, Long: 108.40934, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44423, Long: 108.40849, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44405, Long: 108.4083, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44371, Long: 108.40779, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44388, Long: 108.4076, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  2: [
    { EW: 'E', Lat: 13.44511, Long: 108.40904, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44485, Long: 108.40929, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44448, Long: 108.40885, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44396, Long: 108.40818, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44334, Long: 108.40739, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44264, Long: 108.40794, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  3: [
    { EW: 'E', Lat: 13.44495, Long: 108.40891, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44507, Long: 108.40909, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44527, Long: 108.40887, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44553, Long: 108.40867, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44602, Long: 108.40825, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44628, Long: 108.40806, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4455, Long: 108.40714, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44506, Long: 108.40665, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4448, Long: 108.40672, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  4: [
    { EW: 'E', Lat: 13.44509, Long: 108.409, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44518, Long: 108.40898, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44524, Long: 108.40893, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4457, Long: 108.4086, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44598, Long: 108.40826, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44667, Long: 108.40762, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44715, Long: 108.4073, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44644, Long: 108.40649, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44693, Long: 108.40614, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44746, Long: 108.40574, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  5: [
    { EW: 'E', Lat: 13.44495, Long: 108.40889, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44506, Long: 108.40906, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44576, Long: 108.4085, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44603, Long: 108.40824, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44633, Long: 108.40797, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44664, Long: 108.40768, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44602, Long: 108.40686, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44488, Long: 108.40546, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44513, Long: 108.40529, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44537, Long: 108.40519, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  6: [
    { EW: 'E', Lat: 13.44493, Long: 108.40884, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44487, Long: 108.40875, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44508, Long: 108.40906, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44494, Long: 108.4092, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44461, Long: 108.40944, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44398, Long: 108.41007, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44352, Long: 108.4105, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44318, Long: 108.41105, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4427, Long: 108.41179, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44247, Long: 108.41206, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  7: [
    { EW: 'E', Lat: 13.44514, Long: 108.40905, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44419, Long: 108.40975, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44375, Long: 108.41036, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44322, Long: 108.41097, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44244, Long: 108.41209, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44163, Long: 108.41159, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44131, Long: 108.41133, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44078, Long: 108.41114, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44111, Long: 108.41052, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44079, Long: 108.41017, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  8: [
    { EW: 'E', Lat: 13.44511, Long: 108.40907, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44535, Long: 108.40888, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44565, Long: 108.40849, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44597, Long: 108.40815, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44626, Long: 108.40799, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44553, Long: 108.40713, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44597, Long: 108.40678, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  9: [
    { EW: 'E', Lat: 13.44519, Long: 108.40907, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44463, Long: 108.40938, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44412, Long: 108.40995, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44361, Long: 108.41038, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44248, Long: 108.41207, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4423, Long: 108.41247, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44161, Long: 108.41238, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44156, Long: 108.41207, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  10: [
    { EW: 'E', Lat: 13.44515, Long: 108.40908, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44478, Long: 108.4093, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44428, Long: 108.40973, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44381, Long: 108.41021, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44342, Long: 108.4108, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44246, Long: 108.41209, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44115, Long: 108.41512, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44157, Long: 108.41568, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44181, Long: 108.416, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44258, Long: 108.41549, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44294, Long: 108.41567, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44318, Long: 108.4159, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44385, Long: 108.41624, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44448, Long: 108.41633, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44463, Long: 108.41618, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44513, Long: 108.4163, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  11: [
    { EW: 'E', Lat: 13.44513, Long: 108.40907, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44429, Long: 108.40976, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4437, Long: 108.4103, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44254, Long: 108.41202, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44191, Long: 108.41342, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44121, Long: 108.41511, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44158, Long: 108.4157, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44184, Long: 108.41592, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44239, Long: 108.41565, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44263, Long: 108.41553, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44328, Long: 108.41598, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44444, Long: 108.41635, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44476, Long: 108.41624, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  12: [
    { EW: 'E', Lat: 13.44497, Long: 108.40888, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4451, Long: 108.40905, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4449, Long: 108.40921, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44475, Long: 108.40929, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44458, Long: 108.40895, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44396, Long: 108.40816, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44376, Long: 108.40796, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4437, Long: 108.40771, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44401, Long: 108.40748, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  13: [
    { EW: 'E', Lat: 13.44496, Long: 108.40892, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44506, Long: 108.40903, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44495, Long: 108.40924, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44445, Long: 108.40959, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44404, Long: 108.41002, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44337, Long: 108.41077, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  14: [
    { EW: 'E', Lat: 13.44521, Long: 108.40907, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44472, Long: 108.40934, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44405, Long: 108.40999, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44342, Long: 108.4106, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44176, Long: 108.41388, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44095, Long: 108.41544, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44061, Long: 108.41583, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  15: [
    { EW: 'E', Lat: 13.44518, Long: 108.40908, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44505, Long: 108.40917, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44475, Long: 108.40934, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44414, Long: 108.40997, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44372, Long: 108.41027, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4426, Long: 108.41196, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44204, Long: 108.41315, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44173, Long: 108.41389, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44093, Long: 108.41324, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44046, Long: 108.41259, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44034, Long: 108.41245, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  16: [
    { EW: 'E', Lat: 13.4452, Long: 108.40907, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44479, Long: 108.40934, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44437, Long: 108.40979, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44369, Long: 108.4103, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44268, Long: 108.40962, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44227, Long: 108.4101, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44187, Long: 108.41047, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4415, Long: 108.41009, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  17: [
    { EW: 'E', Lat: 13.44495, Long: 108.40886, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44508, Long: 108.40912, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44478, Long: 108.40942, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44395, Long: 108.40813, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44336, Long: 108.40731, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44265, Long: 108.40646, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44163, Long: 108.40726, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44119, Long: 108.40758, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  18: [
    { EW: 'E', Lat: 13.44498, Long: 108.40892, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44506, Long: 108.40908, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44574, Long: 108.40849, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44624, Long: 108.40804, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44545, Long: 108.40716, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44435, Long: 108.40577, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44482, Long: 108.40539, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44531, Long: 108.4052, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  19: [
    { EW: 'E', Lat: 13.44497, Long: 108.40889, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44507, Long: 108.40911, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44546, Long: 108.40879, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44598, Long: 108.40832, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44598, Long: 108.40832, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44665, Long: 108.40764, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44719, Long: 108.40723, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4465, Long: 108.40648, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44686, Long: 108.40615, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44745, Long: 108.40578, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44747, Long: 108.40568, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  20: [
    { EW: 'E', Lat: 13.4451, Long: 108.40921, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4448, Long: 108.40923, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44432, Long: 108.40971, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44326, Long: 108.41076, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44242, Long: 108.41204, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44169, Long: 108.41413, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44107, Long: 108.41522, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44176, Long: 108.4161, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44224, Long: 108.41569, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44255, Long: 108.41554, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44301, Long: 108.4158, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44428, Long: 108.41629, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
};
export const route_to_8 = {
  1: [
    { EW: 'E', Lat: 13.43645, Long: 108.40835, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4361, Long: 108.40824, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43561, Long: 108.40802, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43521, Long: 108.40813, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43468, Long: 108.40834, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43479, Long: 108.40899, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43411, Long: 108.40917, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  2: [
    { EW: 'E', Lat: 13.43631, Long: 108.40839, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43599, Long: 108.40823, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43563, Long: 108.408, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43523, Long: 108.40817, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43459, Long: 108.40831, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43444, Long: 108.40785, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43405, Long: 108.40649, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43388, Long: 108.40612, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43404, Long: 108.40604, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43446, Long: 108.40627, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43524, Long: 108.40663, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43569, Long: 108.4072, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  3: [
    { EW: 'E', Lat: 13.43632, Long: 108.40837, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43604, Long: 108.40813, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4356, Long: 108.40799, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43517, Long: 108.40817, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43472, Long: 108.40832, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4348, Long: 108.40902, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4341, Long: 108.40916, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43383, Long: 108.40934, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43375, Long: 108.40992, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43311, Long: 108.40993, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43293, Long: 108.40986, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  4: [
    { EW: 'E', Lat: 13.43632, Long: 108.40841, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43595, Long: 108.40809, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43559, Long: 108.408, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43517, Long: 108.40816, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4347, Long: 108.40837, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43491, Long: 108.40899, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43536, Long: 108.41016, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43668, Long: 108.41413, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43723, Long: 108.41551, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  5: [
    { EW: 'E', Lat: 13.43634, Long: 108.40838, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43604, Long: 108.4082, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43569, Long: 108.40796, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43517, Long: 108.40813, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43473, Long: 108.40831, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43527, Long: 108.41029, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43566, Long: 108.41021, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43637, Long: 108.40977, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43772, Long: 108.40891, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.438, Long: 108.40874, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4381, Long: 108.4086, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43816, Long: 108.40848, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43812, Long: 108.40831, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43766, Long: 108.40687, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  6: [
    { EW: 'E', Lat: 13.43639, Long: 108.40832, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43607, Long: 108.40824, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43571, Long: 108.40798, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43519, Long: 108.40814, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43469, Long: 108.40835, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4354, Long: 108.41017, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4358, Long: 108.4102, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43639, Long: 108.40976, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43789, Long: 108.40882, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43808, Long: 108.40871, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43804, Long: 108.4085, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43816, Long: 108.40847, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43848, Long: 108.40841, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43959, Long: 108.40834, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43968, Long: 108.40856, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43963, Long: 108.40922, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  7: [
    { EW: 'E', Lat: 13.43639, Long: 108.40833, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43595, Long: 108.40812, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43568, Long: 108.40796, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43522, Long: 108.40811, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43468, Long: 108.40834, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43538, Long: 108.4102, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43693, Long: 108.41449, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43747, Long: 108.41619, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  8: [
    { EW: 'E', Lat: 13.43625, Long: 108.40837, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43593, Long: 108.40814, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4357, Long: 108.40801, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4353, Long: 108.40804, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4347, Long: 108.40833, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43537, Long: 108.41024, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43568, Long: 108.41025, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43641, Long: 108.40975, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43754, Long: 108.40907, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43802, Long: 108.40876, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43816, Long: 108.40847, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43791, Long: 108.40759, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43763, Long: 108.40691, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43721, Long: 108.40587, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43665, Long: 108.40452, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  9: [
    { EW: 'E', Lat: 13.43628, Long: 108.40844, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43603, Long: 108.40818, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43563, Long: 108.40801, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43524, Long: 108.40817, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4347, Long: 108.40833, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43482, Long: 108.40892, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43411, Long: 108.40926, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43386, Long: 108.40941, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43373, Long: 108.40995, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43316, Long: 108.41003, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43319, Long: 108.41047, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43305, Long: 108.41063, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43187, Long: 108.41035, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43155, Long: 108.4122, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  10: [
    { EW: 'E', Lat: 13.43621, Long: 108.40842, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43601, Long: 108.40813, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43572, Long: 108.40798, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43552, Long: 108.40799, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43514, Long: 108.40818, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43466, Long: 108.40841, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43481, Long: 108.40897, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43405, Long: 108.40918, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43384, Long: 108.40938, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43373, Long: 108.40987, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43317, Long: 108.41003, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43312, Long: 108.41047, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43309, Long: 108.41062, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43182, Long: 108.41033, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43159, Long: 108.41217, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43155, Long: 108.4128, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  11: [
    { EW: 'E', Lat: 13.43634, Long: 108.40842, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43584, Long: 108.40808, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4355, Long: 108.4079, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43522, Long: 108.40823, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4347, Long: 108.40831, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43545, Long: 108.41021, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43579, Long: 108.41011, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43658, Long: 108.40962, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43784, Long: 108.4089, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.438, Long: 108.40873, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43809, Long: 108.40845, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43818, Long: 108.40882, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43815, Long: 108.40964, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43821, Long: 108.41053, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.438, Long: 108.41296, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43806, Long: 108.41435, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  12: [
    { EW: 'E', Lat: 13.43637, Long: 108.40841, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43584, Long: 108.40801, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43537, Long: 108.40803, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43517, Long: 108.40814, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43474, Long: 108.40832, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43484, Long: 108.40892, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43532, Long: 108.41023, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4337, Long: 108.41032, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43322, Long: 108.41037, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43307, Long: 108.41066, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  13: [
    { EW: 'E', Lat: 13.4363, Long: 108.40837, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43568, Long: 108.40788, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43471, Long: 108.40838, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43388, Long: 108.40623, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43347, Long: 108.40471, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  14: [
    { EW: 'E', Lat: 13.43644, Long: 108.40833, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43602, Long: 108.40823, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43566, Long: 108.4079, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43468, Long: 108.40835, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4354, Long: 108.41027, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43566, Long: 108.41029, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43663, Long: 108.40952, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43765, Long: 108.40892, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43802, Long: 108.40875, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43813, Long: 108.4085, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43832, Long: 108.40834, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43962, Long: 108.40835, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43972, Long: 108.40844, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  15: [
    { EW: 'E', Lat: 13.43633, Long: 108.40837, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43634, Long: 108.40845, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43592, Long: 108.40812, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43552, Long: 108.40798, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43477, Long: 108.4083, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43483, Long: 108.40896, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43406, Long: 108.40918, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43381, Long: 108.40943, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4337, Long: 108.40991, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4337, Long: 108.41031, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43361, Long: 108.41124, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4339, Long: 108.41224, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43405, Long: 108.41244, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43406, Long: 108.41317, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4346, Long: 108.41315, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43413, Long: 108.41607, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4352, Long: 108.41613, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  16: [
    { EW: 'E', Lat: 13.43637, Long: 108.40845, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43596, Long: 108.40808, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43557, Long: 108.40799, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43521, Long: 108.40814, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43464, Long: 108.40837, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43537, Long: 108.41022, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43573, Long: 108.41023, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43796, Long: 108.40879, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43806, Long: 108.40852, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43821, Long: 108.40845, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43959, Long: 108.40832, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4397, Long: 108.40886, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43959, Long: 108.40987, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.44016, Long: 108.40959, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  17: [
    { EW: 'E', Lat: 13.43627, Long: 108.40837, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43577, Long: 108.40799, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43536, Long: 108.40812, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43474, Long: 108.40835, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43501, Long: 108.40915, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43533, Long: 108.41024, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4357, Long: 108.41016, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43747, Long: 108.40914, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43798, Long: 108.40874, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43813, Long: 108.40842, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4378, Long: 108.4073, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43703, Long: 108.40547, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43499, Long: 108.40031, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43911, Long: 108.39872, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  18: [
    { EW: 'E', Lat: 13.43627, Long: 108.40843, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43592, Long: 108.40813, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43548, Long: 108.40796, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43468, Long: 108.40833, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43351, Long: 108.4048, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43348, Long: 108.40471, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43267, Long: 108.40456, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  19: [
    { EW: 'E', Lat: 13.43645, Long: 108.40837, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43597, Long: 108.40804, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43569, Long: 108.40801, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43526, Long: 108.40817, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43473, Long: 108.40829, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43389, Long: 108.40619, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43295, Long: 108.4019, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43231, Long: 108.39855, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  20: [
    { EW: 'E', Lat: 13.43626, Long: 108.40838, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43578, Long: 108.40804, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43551, Long: 108.408, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43498, Long: 108.40819, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4347, Long: 108.40827, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43567, Long: 108.41109, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43733, Long: 108.41573, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43753, Long: 108.41633, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43737, Long: 108.41829, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43785, Long: 108.4181, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43882, Long: 108.41768, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43905, Long: 108.41809, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43932, Long: 108.41801, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.43951, Long: 108.41798, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
};
export const route_to_10 = {
  1: [
    { EW: 'E', Lat: 13.49602, Long: 108.39176, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49673, Long: 108.39162, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4976, Long: 108.39133, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49861, Long: 108.39105, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49941, Long: 108.39071, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  2: [
    { EW: 'E', Lat: 13.49597, Long: 108.39174, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49677, Long: 108.39156, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50046, Long: 108.39038, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50139, Long: 108.39014, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  3: [
    { EW: 'E', Lat: 13.49596, Long: 108.39178, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49761, Long: 108.39135, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50045, Long: 108.39038, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50135, Long: 108.39023, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50176, Long: 108.39184, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.503, Long: 108.39521, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  4: [
    { EW: 'E', Lat: 13.49584, Long: 108.39189, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49661, Long: 108.3918, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49774, Long: 108.39135, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50327, Long: 108.3897, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50527, Long: 108.38916, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50448, Long: 108.38534, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50295, Long: 108.38598, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  5: [
    { EW: 'E', Lat: 13.49577, Long: 108.39131, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49598, Long: 108.39274, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49698, Long: 108.39238, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50166, Long: 108.39098, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5017, Long: 108.39169, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50341, Long: 108.3956, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50185, Long: 108.39727, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49991, Long: 108.39777, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49978, Long: 108.40006, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50199, Long: 108.40193, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  6: [
    { EW: 'E', Lat: 13.49604, Long: 108.39178, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49427, Long: 108.39236, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49477, Long: 108.39566, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49485, Long: 108.3998, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49187, Long: 108.40487, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49222, Long: 108.40746, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  7: [
    { EW: 'E', Lat: 13.49588, Long: 108.39184, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49419, Long: 108.39238, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49168, Long: 108.39307, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49185, Long: 108.39405, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49058, Long: 108.39427, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48795, Long: 108.39468, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48701, Long: 108.39474, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  8: [
    { EW: 'E', Lat: 13.49596, Long: 108.39204, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49437, Long: 108.39223, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49166, Long: 108.39285, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49218, Long: 108.39605, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48717, Long: 108.39678, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  9: [
    { EW: 'E', Lat: 13.4957, Long: 108.3912, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49599, Long: 108.39184, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50418, Long: 108.38987, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50547, Long: 108.38905, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50647, Long: 108.38953, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50818, Long: 108.38987, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50827, Long: 108.39283, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50702, Long: 108.39476, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50618, Long: 108.39618, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  10: [
    { EW: 'E', Lat: 13.49586, Long: 108.39193, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49951, Long: 108.39086, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5035, Long: 108.38976, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50531, Long: 108.38897, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50629, Long: 108.38888, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50665, Long: 108.3894, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50621, Long: 108.38948, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50613, Long: 108.3897, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  11: [
    { EW: 'E', Lat: 13.49574, Long: 108.39101, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49578, Long: 108.39131, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.496, Long: 108.39176, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49595, Long: 108.39272, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49447, Long: 108.39306, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49479, Long: 108.39552, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49219, Long: 108.39601, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  12: [
    { EW: 'E', Lat: 13.49579, Long: 108.39128, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49582, Long: 108.39184, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49442, Long: 108.39225, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49463, Long: 108.39536, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.495, Long: 108.39983, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49239, Long: 108.40405, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49175, Long: 108.40534, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49223, Long: 108.40742, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49131, Long: 108.41051, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49037, Long: 108.41377, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48895, Long: 108.41628, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  13: [
    { EW: 'E', Lat: 13.49575, Long: 108.39122, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49584, Long: 108.39178, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49431, Long: 108.39216, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49469, Long: 108.3956, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49876, Long: 108.39493, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49899, Long: 108.39513, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50001, Long: 108.39789, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49961, Long: 108.40004, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50191, Long: 108.40186, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50187, Long: 108.4027, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  14: [
    { EW: 'E', Lat: 13.49596, Long: 108.39165, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49675, Long: 108.39144, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49888, Long: 108.39094, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50527, Long: 108.38905, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5045, Long: 108.38541, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50293, Long: 108.38616, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  15: [
    { EW: 'E', Lat: 13.49607, Long: 108.39186, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49427, Long: 108.39227, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49164, Long: 108.39292, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49174, Long: 108.39418, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48888, Long: 108.39457, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  16: [
    { EW: 'E', Lat: 13.49579, Long: 108.39126, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49599, Long: 108.39177, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49447, Long: 108.39225, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49262, Long: 108.39248, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49177, Long: 108.39298, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49152, Long: 108.39155, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  17: [
    { EW: 'E', Lat: 13.49577, Long: 108.39133, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49587, Long: 108.39189, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49429, Long: 108.39238, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4945, Long: 108.39463, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49176, Long: 108.39495, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.4898, Long: 108.39523, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.48974, Long: 108.39446, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  18: [
    { EW: 'E', Lat: 13.49577, Long: 108.39122, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49563, Long: 108.39191, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49748, Long: 108.39128, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50137, Long: 108.39013, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5051, Long: 108.38901, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50592, Long: 108.38886, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50629, Long: 108.38884, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.5066, Long: 108.38918, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50633, Long: 108.38942, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50629, Long: 108.38959, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50617, Long: 108.3897, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  19: [
    { EW: 'E', Lat: 13.496, Long: 108.39184, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49579, Long: 108.39195, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49596, Long: 108.39266, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49692, Long: 108.39244, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50062, Long: 108.39124, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50158, Long: 108.39101, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50176, Long: 108.3921, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50224, Long: 108.39332, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50337, Long: 108.3956, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50379, Long: 108.39551, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50416, Long: 108.39551, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50414, Long: 108.39667, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50333, Long: 108.39729, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50379, Long: 108.39807, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50407, Long: 108.39846, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
  20: [
    { EW: 'E', Lat: 13.49605, Long: 108.39181, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49766, Long: 108.39134, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.49948, Long: 108.39072, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
    { EW: 'E', Lat: 13.50141, Long: 108.39015, NS: 'N', Speed: 2.149, True_course: 0.81, time: '2024-08-01T18:00:00Z' },
  ],
};

// Quang Trung - 1
// [13.54390, 108.25731], [13.54446, 108.25905]
// [13.54488, 108.26092], [13.54501, 108.26113]
// [13.54501, 108.26113], [13.54587, 108.26256]
// [13.54488, 108.26092], [13.54669, 108.25964]
// [13.54488, 108.26091], [13.54343, 108.26192]
// [13.54501, 108.26113], [13.54637, 108.26027]
// [13.54500, 108.26112], [13.54410, 108.26170]
