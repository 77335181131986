import { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import { Box, Typography, Button, useTheme } from '@mui/material';

import { tokens } from '../../theme';
import { useLanguage, langTokens } from '../../language';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import viLocale from 'date-fns/locale/vi';

import Cluster from '../../components/maps/Cluster';
import PlainMap from '../../components/maps/PlainMap';
import StatBox from '../../components/StatBox';
import SteppedFilter from '../../components/SteppedFilter-x.js';
import ResultBarChart from '../../components/ResultBarChart.jsx';

import DirectionsBikeIcon from '@mui/icons-material/DirectionsBikeOutlined';
// import WhereToVoteIcon from '@mui/icons-material/WhereToVoteOutlined';
import SavingsLeafIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import 'leaflet/dist/leaflet.css';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import '../dashboard/Datetimerange.css';

// import { roads_3 as roads3, roads_11 as roads11 } from '../../data/geoCoding.js';

const Public = () => {
  const _timeRange = JSON.parse(localStorage.getItem('timeRange'));
  const [initData, setInitData] = useState(null);
  const [value, setValue] = useState([new Date(parseFloat(_timeRange.from)), new Date(parseFloat(_timeRange.to))]);
  const [school, setSchool] = useState([]);
  const [roadData, setRoadData] = useState(null);
  // const [density, setDensity] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const convertUTCToLocalTime = (utcString) => {
    const zonedDate = formatInTimeZone(new Date(utcString), 'Asia/Bangkok', 'yyyy-MM-dd HH:mm:ss');
    return zonedDate;
  };
  const groupByDayInLocalTime = (data) => {
    // console.log(data);
    const result = {};
    data.forEach((item) => {
      // Convert the GMT time to the local time zone from GMT time string and Format the local date-time
      const localTimeString = convertUTCToLocalTime(item.time);
      // Extract the local date in YYYY-MM-DD format
      const localDate = localTimeString.split(' ')[0];

      if (!result[localDate]) {
        result[localDate] = [];
      }
      result[localDate].push(item);
    });
    // console.log(result);
    return result;
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1000);

    // Clean up the timeout if the component is unmounted
    return () => clearTimeout(timeoutId);
  }, [isExpanded]);
  // Get data
  useEffect(() => {
    // console.log(value);
    const json_obj = {
      token: 'O1L486UPS9MVY7jcihhe4idshRBb0TyD',
      stt: 1, //1 is for the first 50 records found in the timerange.
      from: new Date(value[0]).getTime() + 'ms', //convert local time to timestampt in millisec
      to: new Date(value[1]).getTime() + 'ms', //convert local time to timestampt in millisec
    };
    // console.log(json_obj);
    const url_getData = process.env.REACT_APP_API_FULLDATAPRESETTC + '?json=' + JSON.stringify(json_obj);
    // console.log(url_getData);
    const fetchData = async () => {
      try {
        fetch(url_getData)
          .then((response) => response.json())
          .then((dt) => filterGPSData_v3(dt))
          .then((filtererDt) => {
            setInitData(filtererDt);
            localStorage.setItem('initData', JSON.stringify(filtererDt));
            const timeRange = {
              from: json_obj.from,
              to: json_obj.to,
            };
            localStorage.setItem('timeRange', JSON.stringify(timeRange));
            setIsExpanded(false);
          });
      } catch (err) {
        console.error('Fetch dataPreSet: ' + err);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [value]);
  // Get roadData related to the selected school
  useEffect(() => {
    const getRoads = async (_id) => {
      const apiURL = process.env.REACT_APP_API_GET_ROADS;
      const token = 'O1L486UPS9MVY7jcihhe4idshRBb0TyD';
      const _params = {
        token,
        schoolID: _id,
      };
      try {
        const response = await axios.get(apiURL, {
          params: {
            json: JSON.stringify(_params),
          },
        });

        if (response.status === 200) {
          const dt = response.data.road;
          // console.log(dt);
          const processedData = dt.map((item) => {
            const _plot =
              item[7] === null
                ? [
                    [item[2], item[4]],
                    [item[3], item[5]],
                  ]
                : JSON.parse(item[7]);
            return {
              id: item[0],
              road: item[1],
              lat1: item[2],
              lat2: item[3],
              long1: item[4],
              long2: item[5],
              schoolID: item[6],
              plot: _plot,
              count: item[8],
              ratio: item[9],
              color: item[10],
            };
          });
          // console.log(processedData);
          setRoadData(processedData);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    if (school.length > 0) getRoads(school[0]);
  }, [school]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { language } = useLanguage();
  const strLang = langTokens(language).dashboard;

  const filterGPSData_v3 = (dataArr) => {
    // console.log(dataArr);
    const standStillThreshold = 40; //bottomThresholdSpeed * period = 40 metre
    const filteredObj = {};
    let _totalDistance = 0;

    Object.keys(dataArr).forEach((key) => {
      const filteredObjValue = {};
      if (key !== 'count') {
        const _dt = dataArr[key];
        const _dt_Days = groupByDayInLocalTime(_dt);

        Object.keys(_dt_Days).forEach((key) => {
          let filteredArr = [],
            _tempTotalDistance = 0,
            _prevPrevPt = null,
            _prevPt = _dt_Days[key][0];

          //insert first point of the day
          filteredArr.push(_prevPt);
          // console.log(dtElementDays[key]);
          for (let i = 1; i < _dt_Days[key].length; i++) {
            const _currPt = _dt_Days[key][i];
            const _dist = calcD(_currPt, _prevPt);
            let _goodPoint = false;
            if (_dist > standStillThreshold) {
              // _goodPoint = !_prevPrevPt ? true : calcAngle(_currPt, _prevPt, _prevPrevPt) > 35 ? true : false;
              _goodPoint = !_prevPrevPt ? true : calcAngle(_currPt, _prevPt, _prevPrevPt);
              if (_goodPoint) {
                filteredArr.push(_currPt);
                _tempTotalDistance += _dist;
                _prevPrevPt = _prevPt;
                _prevPt = _currPt;
              }
            }
          }
          // console.log(filteredArr);
          if (filteredArr.length > 3) {
            filteredObjValue[key] = filteredArr;
            _totalDistance += _tempTotalDistance;
          }
        });
        if (Object.keys(filteredObjValue).length !== 0) filteredObj[key] = filteredObjValue;
      }
    });

    const result = { filteredObj, totalDistance: Math.round(_totalDistance / 10) / 100 };
    // console.log(result);
    return result;
  };

  const toCartesian = (lat, long) => {
    const R = 6371; // Earth's radius in kilometers
    const latRad = (lat * Math.PI) / 180;
    const lonRad = (long * Math.PI) / 180;

    const x = R * Math.cos(latRad) * Math.cos(lonRad);
    const y = R * Math.cos(latRad) * Math.sin(lonRad);
    const z = R * Math.sin(latRad);

    return { x, y, z };
  };
  const calcAngle = (coord1, coord2, coord3) => {
    const p1 = toCartesian(coord1.Lat, coord1.Long);
    const p2 = toCartesian(coord2.Lat, coord2.Long);
    const p3 = toCartesian(coord3.Lat, coord3.Long);

    const v1 = {
      x: p1.x - p2.x,
      y: p1.y - p2.y,
      z: p1.z - p2.z,
    };

    const v2 = {
      x: p3.x - p2.x,
      y: p3.y - p2.y,
      z: p3.z - p2.z,
    };

    const dotProduct = v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;
    const magnitudeV1 = Math.sqrt(v1.x * v1.x + v1.y * v1.y + v1.z * v1.z);
    const magnitudeV2 = Math.sqrt(v2.x * v2.x + v2.y * v2.y + v2.z * v2.z);

    const cosineTheta = dotProduct / (magnitudeV1 * magnitudeV2);
    const angle = Math.acos(cosineTheta) * (180 / Math.PI); // Convert radians to degrees
    if (angle < 35 && calcD(coord2, coord2) < 40) {
      return false;
    } else {
      return true;
    }
  };
  const calcD = (coord1, coord2) => {
    const R = 6371 * 1000; // Radius of the Earth in meters
    const toRad = (degrees) => {
      return degrees * (Math.PI / 180);
    };
    const lat1 = toRad(coord1.Lat);
    const lon1 = toRad(coord1.Long);
    const lat2 = toRad(coord2.Lat);
    const lon2 = toRad(coord2.Long);

    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    const a = Math.sin(dLat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;

    return distance; // Distance in meters
  };
  const calculateSavingCO2 = (distance, kg) => {
    const _convertCoEff = kg ? 1e3 : 1e6;
    const _co2 = (distance * 45.68) / _convertCoEff;
    const roundingCoeff = _co2 < 1 ? 1e4 : 1e2;
    return Math.round(_co2 * roundingCoeff) / roundingCoeff;
  };

  return (
    <Box m='-53px 0 0 15px' width={'calc(100% - 25px)'}>
      <Box display='flex' justifyContent='flex-start' alignItems='center'>
        <Header title={strLang.DASHBOARD} subtitle={strLang.WELCOME} />
      </Box>
      {/* GRID & CHARTS */}
      <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gridAutoRows='calc(100vh / 6 - 27px)' gap={'10px'}>
        {/* ROW 1 */}
        {initData && (
          <>
            <Box gridColumn='span 1' bgcolor={colors.primary[400]} display='flex' alignItems='center' position={'relative'}>
              <StatBox
                title={strLang.TOTAL_DISTANCE}
                subtitle={strLang.TRAVEL}
                figure={Math.round(initData.totalDistance * 100) / 100}
                unit={'km'}
                icon={<DirectionsBikeIcon sx={{ color: colors.greenAccent[300], fontSize: '26px' }} />}
                tutorial={{ title: strLang.TOTAL_DISTANCE, desc: strLang.TOTAL_DISTANCE_DESC }}
              />
            </Box>
            <Box gridColumn='span 1' bgcolor={colors.greenAccent[950]} display='flex' alignItems='center' position={'relative'}>
              <StatBox
                title={strLang.CO2_EMISSION}
                subtitle={strLang.CO2_EMISSION_2}
                figure={calculateSavingCO2(initData.totalDistance)} //CO2 emmision for motorcycle: 45.68 g/km
                unit={strLang.CO2_UNIT}
                icon={<SavingsLeafIcon sx={{ color: colors.greenAccent[300], fontSize: '26px' }} />}
                tutorial={{ title: strLang.CO2_EMISSION, desc: strLang.CO2_EMISSION_DESC }}
              />
            </Box>
            <Box gridColumn='span 1' bgcolor={colors.primary[400]} display='flex' alignItems='center' position={'relative'}>
              <SteppedFilter value={value} setValue={setValue} language={strLang} setSchool={setSchool} /> {/*setDensity={setDensity} */}
            </Box>
          </>
        )}
        {/* Map 3 */}
        <Box gridColumn={isExpanded ? 'span 3' : 'span 2'} gridRow='span 5' bgcolor={colors.primary[400]} sx={{ position: 'relative' }}>
          <Button
            variant='outlined'
            onClick={toggleExpand}
            sx={{ position: 'absolute', top: '80px', left: '10px', zIndex: 99999, minWidth: '34px', width: '34px' }}>
            {isExpanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </Button>
          {/* <Box width='100%' height={isExpanded ? 'calc(100vh / 6 * 5 - 95px)' : 'calc(100vh / 6 * 4 - 78px)'} id='map'> */}
          <Box width='100%' height={'100%'} id='map'>
            {initData && (
              <>
                {school.length > 0 ? (
                  <PlainMap schoolId={school[0]} roadData={roadData} viewMode={'public'} />
                ) : (
                  <Cluster dataArray={initData.filteredObj} />
                )}
              </>
            )}
          </Box>
        </Box>
        {!isExpanded && (
          <>
            <Box gridColumn='span 1' gridRow='span 5' bgcolor={colors.primary[400]} width={'100%'}>
              <Box m='15px 0' height={'calc(100% - 30px)'} overflow={'auto'}>
                <Box p='0 25px' width={'100%'}>
                  <Box sx={{ '& p': { margin: '6px 0' } }}>
                    <Typography variant='h5' fontSize={'17px'} fontWeight={'bold'} color={colors.greenAccent[300]} m={'0.5em 0'}>
                      I. {school.length > 0 ? strLang.SCHOOL_INFO : strLang.TIME_INFO}
                    </Typography>
                    <p>
                      - <b>{school.length > 0 ? strLang.SCHOOL_NAME : strLang.TIME_START}:</b>{' '}
                      {school.length > 0
                        ? school[1]
                        : format(new Date(value[0]), 'EEE d/LL/y', strLang.LOCALE === 'vi-VI' ? { locale: viLocale } : '')}
                    </p>
                    <p>
                      - <b>{school.length > 0 ? strLang.SCHOOL_ADDRESS : strLang.TIME_END}:</b>{' '}
                      {school.length > 0
                        ? school[4]
                        : format(new Date(value[1]), 'EEE d/LL/y', strLang.LOCALE === 'vi-VI' ? { locale: viLocale } : '')}
                    </p>
                    <Typography variant='h5' fontSize={'17px'} fontWeight={'bold'} color={colors.greenAccent[300]} m={'1.5em 0 0.5em'}>
                      II. {strLang.RESULT_CHARTS}
                    </Typography>
                    {school.length > 0 && roadData ? (
                      <ResultBarChart
                        resultData={roadData.sort((a, b) => b.ratio - a.ratio)}
                        labels={{
                          no: strLang.RESULT_NO,
                          routes: strLang.RESULT_ROUTES,
                          ratios: strLang.RESULT_RATIOS,
                          results: strLang.RESULT_RESULTS,
                        }}
                      />
                    ) : (
                      <Typography variant='font10Bold'>{language === 'en' ? '- Data not yet available' : ' - Chưa có dữ liệu'}</Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* Box at the bottom */}
            {/* TẠM THỜI DISABLE - CÓ THỂ SỬ DỤNG TRONG TƯƠNG LAI */}
            {/* <Box gridColumn='span 2' gridRow='span 1' bgcolor={colors.primary[400]}>
                  <Box m='15px 0' height={'calc(100% - 30px)'} overflow={'auto'}>
                    <Box p='0 20px' display='flex' justifyContent='space-between' alignItems='center'>
                      <Box>
                        <Typography variant='h4' fontWeight='bold' color={colors.grey[100]}>
                          {strLang.ADDITIONAL_INFO}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box> */}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Public;
